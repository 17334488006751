import { DeleteOutlined, EditFilled, EyeFilled, FileZipOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, Tooltip, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../../helper/localStorage";
import CustomInput from "../../common/index";
import { CustomModal } from "../../common/modal";
import { CustomTable } from "../../common/tables";

const { Link } = Typography;
let userInfo;
const Projects = ({ project }) => {
    try {
        userInfo = getItem("sm-user-info");
    } catch (error) {}

    const [addProjectForm] = Form.useForm();
    const [showExtra, setShowExtra] = useState(false);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const columns = [
        {
            title: "Project ID",
            dataIndex: "project_id",
            width: 120,
            filteredValue: [search],
            onFilter: (value, record) => {
                return (
                    String(record.project_id).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.project_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.customers).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.createdAt).toLowerCase().includes(value.toLowerCase())
                );
            },
        },
        {
            title: "Project Name",
            dataIndex: "project_name",
            width: 150,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            width: 50,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 80,
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title="Details">
                            <Button className="action-btn-view mr-1" type="primary" shape="squre" icon={<EyeFilled />} onClick={() => navigate(`/projects/${record.id}${record.customers ? `?customer_id=${record.customers[0]?._id}` : ""}`)} />
                        </Tooltip>
                        {userInfo.role === "admin" ? (
                            <>
                                <Tooltip title="Edit Project">
                                    <Button className="action-btn-edit mr-1" type="text" shape="squre" icon={<EditFilled className="fs-11" />} />
                                </Tooltip>
                                <Tooltip title="Archive Project">
                                    <Popconfirm title="Are you sure to Archive this Project?" onConfirm={() => project.archiveProject(record.id)} okText="Yes" cancelText="No">
                                        <Button icon={<DeleteOutlined className="fs-11" />} className="action-btn-delete mr-1" type="primary" danger shape="squre" />
                                    </Popconfirm>
                                </Tooltip>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                    // <Dropdown trigger="click" menu={{ items: userInfo.role === "admin" ? items : itemsUser, onClick: ({ key }) => menuItemClick(key, record) }} onChange>
                    //     <Button icon={<EllipsisOutlined size="large" rotate={90} />} className="action-btn-dots mr-1" type="primary" shape="squre" />
                    // </Dropdown>
                );
            },
        },
    ];

    const columnsAdmin = [
        {
            title: "Project ID",
            dataIndex: "project_id",
            width: 120,
            filteredValue: [search],
            onFilter: (value, record) => {
                return (
                    String(record.project_id).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.project_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.customers).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.createdAt).toLowerCase().includes(value.toLowerCase())
                );
            },
        },
        {
            title: "Project Name",
            dataIndex: "project_name",
            width: 150,
        },
        {
            title: "Customer Name",
            dataIndex: "customers",
            width: 150,
            render: (_, record) => record?.customers[0]?.name || <span className="text-danger">Not assigned yet</span>,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            width: 50,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 80,
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title="Details">
                            <Button className="action-btn-view mr-1" type="primary" shape="squre" icon={<EyeFilled />} onClick={() => navigate(`/projects/${record.id}${record.customers ? `?customer_id=${record.customers[0]?._id}` : ""}`)} />
                        </Tooltip>
                        {userInfo.role === "admin" ? (
                            <>
                                <Tooltip title="Edit Project">
                                    <Button
                                        className="action-btn-edit mr-1"
                                        type="text"
                                        onClick={() => {
                                            addProjectForm.setFieldsValue(record);
                                            project.addProjectModal(true, true);
                                        }}
                                        shape="squre"
                                        icon={<EditFilled className="fs-11" />}
                                    />
                                </Tooltip>
                                <Tooltip title="Archive Project">
                                    <Popconfirm title="Are you sure to Archive this Project?" onConfirm={() => project.archiveProject(record.id)} okText="Yes" cancelText="No">
                                        <Button icon={<FileZipOutlined className="fs-11" />} className="action-btn-delete mr-1" type="primary" danger shape="squre" />
                                    </Popconfirm>
                                </Tooltip>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
        },
    ];

    const initColsValue = { manufacturer: "Steel Mains", A: "Manufacturer", B: "Pipe Details", C: "Rated Pressure", D: "Applicable Standards" };

    const handleFinish = async (values) => {
        let finalUpload = { extra: {} };
        for (const key in values) {
            if (["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(key)) {
                finalUpload.extra[key] = values[key];
            } else {
                finalUpload[key] = values[key];
            }
        }
        await project.addProject(finalUpload, addProjectForm);
    };

    const handleUpdate = (values) => {
        const id = values.id;
        delete values.id;
        project.updateColumns(values, id);
    };

    useEffect(() => {
        project.getAllProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showHeaders = ({ target: { checked } }) => {
        setShowExtra(checked);
    };

    return (
        <>
            <Row gutter={24}>
                {userInfo.role === "admin" ? (
                    <Col className="mt-2 d-flex justify-content-end fs-11 align-items-center" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Link onClick={() => navigate("/archive-project")} className="d-flex justify-content-end fs-11 align-items-center">
                            <DeleteOutlined className="mr-1" />
                            <div>Archive Projects</div>
                        </Link>
                    </Col>
                ) : (
                    <></>
                )}

                <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        data={project.projectData || []}
                        columns={userInfo.role === "admin" ? columnsAdmin : columns}
                        border={true}
                        loading={project.isFetchingData}
                        pagination={true}
                        scroll={{ x: userInfo.role === "admin" ? 550 : 400 }}
                        rowKey={({ key }) => key}
                        title="Projects"
                        children={
                            <div className="d-flex justify-content-center align-items-center">
                                <Form.Item className="p-0 m-0 mr-2">
                                    <Input
                                        className="p-1"
                                        placeholder="Search Projects"
                                        prefix={<SearchOutlined />}
                                        onKeyUp={async ({ target: { value } }) => {
                                            setSearch(value);
                                        }}
                                    />
                                </Form.Item>
                                {userInfo.role === "admin" ? (
                                    <Button
                                        className="bg-primary"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        size="large"
                                        onClick={async () => {
                                            project.addProjectModal(true);
                                        }}
                                    >
                                        Add New Project
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        }
                    />
                </Col>
            </Row>
            <CustomModal visibility={project.showProjectModal} handleCancel={project.addProjectModal} className="add-project-modal" centered title={project.isEditPROJECT ? "Edit Project" : "Add New Project"}>
                <div className="add-project-modal">
                    <Form layout="vertical" initialValues={initColsValue} form={addProjectForm} onFinish={project.isEditPROJECT ? handleUpdate : handleFinish}>
                        <Row gutter={24}>
                            {project.isEditPROJECT ? (
                                <Form.Item label="id" name="id" hidden={true}>
                                    <CustomInput />
                                </Form.Item>
                            ) : (
                                <></>
                            )}
                            <Col span={24}>
                                <Form.Item
                                    label="Project ID"
                                    name="project_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Project ID",
                                        },
                                    ]}
                                >
                                    <CustomInput className="w-100 p-1H7 fs-1" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Project Name"
                                    name="project_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Project Name",
                                        },
                                    ]}
                                >
                                    <CustomInput className="w-100 p-1H7 fs-1" />
                                </Form.Item>
                            </Col>
                            {project.isEditPROJECT ? (
                                <></>
                            ) : (
                                <>
                                    <Col span={24} md={12}>
                                        <Form.Item
                                            label="Column A"
                                            name={userInfo.role === "admin" ? "A" : "K"}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Please enter Column A",
                                            //     },
                                            // ]}
                                        >
                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item
                                            label="Column B"
                                            name={userInfo.role === "admin" ? "B" : "L"}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Please enter Column A",
                                            //     },
                                            // ]}
                                        >
                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item
                                            label="Column C"
                                            name={userInfo.role === "admin" ? "C" : "M"}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Please enter Column C",
                                            //     },
                                            // ]}
                                        >
                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item
                                            label="Column D"
                                            name={userInfo.role === "admin" ? "D" : "N"}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: "Please enter Column D",
                                            //     },
                                            // ]}
                                        >
                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={showHeaders}>Add Extra Headers</Checkbox>
                                    </Col>
                                    {showExtra ? (
                                        <>
                                            <Row gutter={24}>
                                                <Row gutter={24} className="p-2">
                                                    <Col span={24} md={12}>
                                                        <Form.Item
                                                            label="Extra Column E"
                                                            name={userInfo.role === "admin" ? "E" : "O"}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please enter Column E",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} md={12}>
                                                        <Form.Item
                                                            label="Extra Column F"
                                                            name={userInfo.role === "admin" ? "F" : "P"}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please enter Column F",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} md={12}>
                                                        <Form.Item
                                                            label="Extra Column G"
                                                            name={userInfo.role === "admin" ? "G" : "Q"}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please enter Column G",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} md={12}>
                                                        <Form.Item
                                                            label="Extra Column H"
                                                            name={userInfo.role === "admin" ? "H" : "R"}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please enter Column H",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} md={12}>
                                                        <Form.Item
                                                            label="Extra Column I"
                                                            name={userInfo.role === "admin" ? "I" : "S"}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please enter Column I",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} md={12}>
                                                        <Form.Item
                                                            label="Extra Column J"
                                                            name={userInfo.role === "admin" ? "J" : "T"}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please enter Column J",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </Row>
                        <Button block htmlType="submit" loading={project.isFetchingData} size="large" className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                            {project.isEditPROJECT ? "Update" : "Add"}
                        </Button>
                    </Form>
                </div>
            </CustomModal>
        </>
    );
};

export default inject("project")(observer(Projects));
