import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/less/index.less";
import App from "./Routes";
import { Provider } from "mobx-react";
import reportWebVitals from "./reportWebVitals";
import CombinedStore from "./store/CombinedStore";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider {...new CombinedStore()}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
