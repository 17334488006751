/* eslint-disable no-unused-vars */

import { Col, Row, Spin, Radio } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Cosmatics } from "./cosmatics";
import { ShoppingOutlined, ShopOutlined, DollarOutlined, EnterOutlined } from "@ant-design/icons";
import getSymbolFromCurrency from "currency-symbol-map";

// import { useNavigate } from "react-router-dom";
// import { getItem } from "../../../helper/localStorage";
import { CustomTable } from "../../common/tables";
// import { IconCustomers, IconProjects } from "../../sidebar/icons/icon";

// let userInfo;
// let Main_User;

const Dashboard = ({ dashboard, auth }) => {
    // const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const projectColumns = [
        {
            title: "Channel",
            dataIndex: "fulfillment_channel_title",
            align: "left",
            width: "14%",
        },
        {
            title: "Marketplace",
            dataIndex: "marketplace",
            width: "25%",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            width: "26%",
        },
        {
            title: "Orders",
            dataIndex: "orders",
            width: "13%",
        },
        {
            title: "Items",
            dataIndex: "items",
            width: "13%",
        },
    ];

    // try {
    //     userInfo = getItem("sm-user-info");
    //     Main_User = ["systemAdmin", "merchantAdmin"].includes(userInfo.role) ? true : false;
    // } catch (error) {}

    useEffect(() => {
        dashboard.getChannelSales();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const beautifyNumber = (number, round = true) => {
        return Intl.NumberFormat("en-US").format(round ? Math.round(number) : number);
    };

    const changeTimeframe = (e) => {
        dashboard.getChannelSales(e.target.value);
    };

    const convertJSONToData = (json) => {
        const data = [];
        let keyCounter = 1;

        for (const [_, items] of Object.entries(json)) {
            if (items.length > 0) {
                let chldLst = [];
                let amtSum = 0;
                let ordrSum = 0;
                let itmSum = 0;

                for (const item of items) {
                    const childData = {
                        key: keyCounter++,
                        fulfillment_channel_title: (
                            <>
                                <EnterOutlined style={{ transform: "scaleX(-1)", opacity: "0.5" }} /> {item.marketplace}
                            </>
                        ),
                        marketplace: item.marketplace_name,
                        amount: item.amount ? (
                            <>
                                {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.original_amount)} {item.currency}{" "}
                                {item.currency !== auth.user.currency ? (
                                    <b>
                                        ({beautifyNumber(item.amount)} {auth.user.currency}){" "}
                                    </b>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            0
                        ),
                        orders: item.orders ? beautifyNumber(item.orders) : 0,
                        items: item.items ? beautifyNumber(item.items) : 0,
                    };
                    amtSum += item.amount ? item.amount : 0;
                    ordrSum += item.orders ? item.orders : 0;
                    itmSum += item.items ? item.items : 0;

                    chldLst.push(childData);
                }

                data.push({
                    key: keyCounter++,
                    fulfillment_channel_title: items[0].fulfillment_channel_title,
                    amount: <b>{beautifyNumber(amtSum)} {auth.user.currency}</b>,
                    orders: <b>{beautifyNumber(ordrSum)}</b>,
                    items: <b>{beautifyNumber(itmSum)}</b>,
                    children: chldLst,
                });
            }
        }

        return data;
    };

    useEffect(() => {
        if (dashboard.channelSalesData?.result) {
            setSales(convertJSONToData(dashboard.channelSalesData.result));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.channelSalesData?.result]);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Radio.Group optionType="button" onChange={changeTimeframe} className="w-100" buttonStyle="solid" size="small" defaultValue="today">
                        <Radio.Button value="today">Today</Radio.Button>
                        <Radio.Button value="weekly">Weekly</Radio.Button>
                        <Radio.Button value="monthly">Monthly</Radio.Button>
                        <Radio.Button value="quarterly">Quarterly</Radio.Button>
                        <Radio.Button value="all">All</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col className="mt-2 remove-right-gutter" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Cosmatics
                        title="Total Revenue"
                        counts={
                            !dashboard.isFetchingData && (dashboard.channelSalesData?.revenue || dashboard.channelSalesData?.revenue === 0) ? (
                                <p className="cosmatic-counts">{beautifyNumber(dashboard.channelSalesData?.revenue)}</p>
                            ) : (
                                <Spin className="pt-1" spinning={true} />
                            )
                        }
                        icon={<DollarOutlined style={{ fontSize: "26px" }} />}
                    />
                </Col>
                <Col className="mt-2 remove-right-gutter" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Cosmatics
                        title="Total Items"
                        counts={
                            !dashboard.isFetchingData && (dashboard.channelSalesData?.total_orders || dashboard.channelSalesData?.total_orders === 0) ? (
                                <p className="cosmatic-counts">{beautifyNumber(dashboard.channelSalesData?.total_orders)}</p>
                            ) : (
                                <Spin className="pt-1" spinning={true} />
                            )
                        }
                        icon={<ShoppingOutlined style={{ fontSize: "26px" }} />}
                    />
                </Col>
                <Col className="mt-2" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Cosmatics
                        title="Total Items"
                        counts={
                            !dashboard.isFetchingData && (dashboard.channelSalesData?.total_items || dashboard.channelSalesData?.total_items === 0) ? (
                                <p className="cosmatic-counts">{beautifyNumber(dashboard.channelSalesData?.total_items)}</p>
                            ) : (
                                <Spin className="pt-1" spinning={true} />
                            )
                        }
                        icon={<ShopOutlined style={{ fontSize: "26px" }} />}
                    />
                </Col>
            </Row>
            <Row className="mt-1" gutter={24}>
                <Col className="mt-1 my-custom-table" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        style={{ minHeight: 500, maxHeight: 800, overflowY: "scroll" }}
                        loading={dashboard.isFetchingData}
                        data={sales}
                        columns={projectColumns}
                        pagination={false}
                        size="small"
                        scroll={{ x: "100%" }}
                        title="Channel Sales Data"
                    />
                </Col>
            </Row>
        </>
    );
};

export default inject("dashboard", "auth")(observer(Dashboard));
