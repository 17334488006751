import { Button, Col, Form, Row, Typography } from "antd";
import { observer, inject } from "mobx-react";
import React from "react";
import CustomInput from "../../common/index";
import { useNavigate } from "react-router-dom";

function Login({ auth }) {
    const navigate = useNavigate();

    const performLogin = async (values) => {
        await auth.login(values);
    };
    return (
        <Row className="overflow-hidden p-0 m-0" gutter={24}>
            <Col className="login-right p-0 m-0" xs={24} sm={24} md={24} span={24}>
                <Col xs={24} sm={24} md={8} span={8}>
                    <div className="login-helper">
                        <div className="login-helper-title">Login</div>
                        <div className="login-helper-body">
                            <Form layout="vertical" onFinish={performLogin}>
                                <Form.Item
                                    name="Username"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Email",
                                        },
                                    ]}
                                >
                                    <CustomInput className="w-100 fs-13" placeholder="sam@sellerviz.com" />
                                </Form.Item>
                                <Form.Item
                                    name="Password"
                                    label="Password"
                                    className="mb-1"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Password",
                                        },
                                    ]}
                                >
                                    <CustomInput.Password className="w-100 fs-13" placeholder="***********" />
                                </Form.Item>
                                <p className="mt-0 text-right text-black fs-11">
                                    Forgot Password?{" "}
                                    <Typography.Link className="fs-11" onClick={() => navigate("/forgot-password")}>
                                        Here
                                    </Typography.Link>
                                </p>
                                <Button loading={auth.isFetchingData} block htmlType="submit" size="large" className="bg-primary text-white btn-custom-height-1 fs-11">
                                    Login
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Col>
        </Row>
    );
}

export default inject("auth")(observer(Login));
