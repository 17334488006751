import AuthStore from "./Auth";
import CustomerStore from "./Customers";
import DashboardStore from "./Dashboard";
import ManageUserStore from "./MangeUser";
import ProjectsStore from "./Projects";
import RouterStore from "./routeStore";

class CombinedStore {
    constructor(allStore) {
        this.routes = new RouterStore(this);
        this.resetAllStore();
    }

    resetAllStore = () => {
        this.auth = new AuthStore(this);
        this.user = new ManageUserStore(this);
        this.customer = new CustomerStore(this);
        this.project = new ProjectsStore(this);
        this.dashboard = new DashboardStore(this);
    };
}
export default CombinedStore;
