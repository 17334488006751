import axios from "axios";
import { getItem, setItem } from "../../helper/localStorage";

// Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        const token = getItem("sm-user-token");

        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
            config.headers["Content-Type"] = "application/json";
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/refresh-token`) {
            localStorage.clear();
            window.location.href = "/";
            return Promise.reject(error);
        }

        if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/login`) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && originalRequest.url.includes("reset-password-byemail")) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = getItem("sm-user-rf-token");
            return axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/refresh-token`, {
                    refresh_token: refreshToken,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setItem("sm-user-token", res.data.data.access_token);
                        axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.data.access_token;
                        return axios(originalRequest);
                    }
                });
        }
        return Promise.reject(error);
    }
);

export default axios;
