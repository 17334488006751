/* eslint-disable */
import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import AuthService from "../../services/Auth.service";
import { getItem, setItem } from "../../helper/localStorage";

class AuthStore {
    isFetchingData = false;
    showChangePassword = false;
    showUserProfile = false;
    user = {};

    constructor(allStores) {
        makeAutoObservable(this);
        this.allStores = allStores;
        this.loadUser();
    }

    reset = () => {
        this.isFetchingData = false;
        this.showChangePassword = false;
        this.showUserProfile = false;
        this.user = {};
    };

    clearStore = async () => {
        this.reset();
        this.allStores.customer.reset();
        this.allStores.project.reset();
        this.allStores.dashboard.reset();
        this.allStores.user.reset();
    };

    login = async (values) => {
        try {
            this.isFetchingData = true;
            const data = await AuthService.loginService(values);

            if (data.status === 200) {
                const usrData = {
                    email: data.data.data.email,
                    first_name: data.data.data.first_name,
                    last_name: data.data.data.last_name,
                    merchant: data.data.data.merchant,
                    role: data.data.data.role,
                    username: data.data.data.username,
                };

                setItem("sm-user-token", data.data.data.access_token);
                setItem("sm-user-rf-token", data.data.data.refresh_token);

                const userData = await AuthService.fetchMerchantDetailsService();
                this.setUser({ ...usrData, ...userData.data.data });

                message.success(data.data.message);
                this.isFetchingData = false;
                this.allStores.routes.history("/dashboard");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    resetPassword = async (values) => {
        try {
            this.isFetchingData = true;
            const data = await AuthService.resetPasswordService(values);

            if (data.status === 200) {
                message.success(data.data.message);
                this.isFetchingData = false;
                this.openChangePassword(false);
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    setPassword = async (values, token) => {
        try {
            this.isFetchingData = true;
            const data = await AuthService.setPasswordService(values, token);
            if (data.status < 400) {
                message.success("Reset password successfully");
                this.isFetchingData = false;
                this.allStores.routes.history("/");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    forgotPassword = async (values) => {
        try {
            this.isFetchingData = true;
            const data = await AuthService.forgotPasswordService(values);
            if (data.status < 400) {
                message.success("Reset password link has been sent to registered email");
                this.isFetchingData = false;

                this.allStores.routes.history("/");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    logout = async () => {
        try {
            // const refresh_token = getItem("sm-user-rf-token");
            // const data = await AuthService.logoutService({ refreshToken: refresh_token });
            // if (data.status === 204) {
            localStorage.clear();
            await this.clearStore();
            this.allStores.routes.history("/");
            // }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
        }
    };

    openChangePassword = async (visibility) => {
        runInAction(() => {
            this.showChangePassword = visibility;
        });
    };

    openUserProfile = async (visibility) => {
        runInAction(() => {
            this.showUserProfile = visibility;
        });
    };

    setUser(user) {
        this.user = user;
        setItem("sm-user-info", JSON.stringify(user));
    }

    loadUser() {
        const storedUser = getItem("sm-user-info");

        if (storedUser) {
            this.user = storedUser;
        }
    }
}

export default AuthStore;
