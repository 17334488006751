import { Table } from "antd";
import React from "react";

export const CustomTable = (props) => {
    return (
        <div className="custom-table">
            {props.title ? (
                <div className="custom-table-titles">
                    <div className={props.children ? "" : "py-2 w-100"}>{props.title}</div>
                    {props.children}
                </div>
            ) : (
                <></>
            )}

            <Table
                rowClassName={props.rowClassName}
                style={props.style}
                rowKey={props.rowKey}
                size={props.size}
                components={props.components}
                scroll={props.scroll}
                bordered={props.border}
                columns={props.columns}
                dataSource={props.data}
                loading={props.loading}
                rowSelection={props.rowSelection}
                pagination={props.pagination}
                onChange={props.onChange}
            />
        </div>
    );
};
