/* eslint-disable no-unused-vars */

import { EnterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Grid, List, Radio, Row, Select } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../common/index";

// import { useNavigate } from "react-router-dom";
// import { getItem } from "../../../helper/localStorage";
import { CustomTable } from "../../common/tables";
const { useBreakpoint } = Grid;
// import { IconCustomers, IconProjects } from "../../sidebar/icons/icon";

// let userInfo;
// let Main_User;

const Dashboard = ({ dashboard, auth }) => {
    // const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const [filter, setFilter] = useState("");
    const [productList, setProductList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [timeframe, setTimeframe] = useState("today");
    const [marketplaceFilter, setMarketplaceFilter] = useState("");
    const [sellerSkuFilter, setSellerSkuFilter] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [filterForm] = Form.useForm();

    const tblTitles = {
        today: { left: "Average for previous 7 days", right: "Current Day" },
        weekly: { left: "Average for previous 3 weeks", right: "Current Week" },
        monthly: { left: "Average for previous 3 months", right: "Current Month" },
        quarterly: { left: "Average for previous 3 quarterly", right: "Current Quarter" },
        yearly: { left: "Average for previous 1 year", right: "Current Year" },
    };

    const screens = useBreakpoint();
    const projectColumns = [
        {
            title: "Channel",
            key: "Channel",
            dataIndex: "fulfillment_channel_title",
            align: "center",
        },
        {
            title: tblTitles[timeframe].left,
            align: "center",
            dataIndex: "avgPast",
            children: [
                {
                    title: "Sales Rate",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "past_sales_rate",
                },
                {
                    title: "Revenue",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "past_revenue",
                },
                {
                    title: "Unit Price",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "past_unit_price",
                },
            ],
        },
        {
            title: tblTitles[timeframe].right,
            align: "center",
            dataIndex: "avgCurrent",
            children: [
                {
                    title: "Sales Rate",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "current_sales_rate",
                },
                {
                    title: "Revenue",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "current_revenue",
                },
                {
                    title: "Unit Price",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "current_unit_price",
                },
            ],
        },
    ];

    // try {
    //     userInfo = getItem("sm-user-info");
    //     Main_User = ["systemAdmin", "merchantAdmin"].includes(userInfo.role) ? true : false;
    // } catch (error) {}

    const beautifyNumber = (number, round = true) => {
        return Intl.NumberFormat("en-US").format(round ? Math.round(number) : number);
    };

    const channelSKUFilter = (value) => {
        setFilter(value);
    };

    const changeTimeframe = (e) => {
        setTimeframe(e.target.value);
        dashboard.getProductInfoDetailsByChannelSKU(selectedItem, e.target.value, marketplaceFilter, sellerSkuFilter);
    };

    const convertJSONToData = (json) => {
        const transformedData = json.reduce((result, item, index) => {
            const existingChannel = result.find((channel) => channel.fulfillment_channel_title === item.fulfillment_channel_title);
            let sumData = {
                past_unit_price_sum: 0,
                past_revenue_sum: 0,
                current_unit_price: 0,
                current_revenue: 0,
            };

            if (existingChannel) {
                existingChannel.children.push({
                    key: `INNER-${index + 1}`,
                    fulfillment_channel_title: (
                        <>
                            <EnterOutlined style={{ transform: "scaleX(-1)", opacity: "0.5" }} /> {item.marketplace}
                        </>
                    ),
                    past_unit_price: item.past_unit_price ? (
                        <>
                            {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.past_original_unit_price)} {item.currency}{" "}
                            {item.currency !== auth.user.currency ? (
                                <b>
                                    ({beautifyNumber(item.past_unit_price)} {auth.user.currency}){" "}
                                </b>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        "-"
                    ),
                    past_revenue: item.past_revenue ? (
                        <>
                            {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.past_original_revenue)} {item.currency}{" "}
                            {item.currency !== auth.user.currency ? (
                                <b>
                                    ({beautifyNumber(item.past_revenue)} {auth.user.currency}){" "}
                                </b>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        "-"
                    ),
                    past_sales_rate: item.past_sales_rate ? `${beautifyNumber(item.past_sales_rate)}` : "-",
                    current_unit_price: item.current_unit_price ? (
                        <>
                            {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.current_original_unit_price)} {item.currency}{" "}
                            {item.currency !== auth.user.currency ? (
                                <b>
                                    ({beautifyNumber(item.current_unit_price)} {auth.user.currency}){" "}
                                </b>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        "-"
                    ),
                    current_revenue: item.current_revenue ? (
                        <>
                            {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.current_original_revenue)} {item.currency}{" "}
                            {item.currency !== auth.user.currency ? (
                                <b>
                                    ({beautifyNumber(item.current_revenue)} {auth.user.currency}){" "}
                                </b>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        "-"
                    ),
                    current_sales_rate: item.current_sales_rate ? `${beautifyNumber(item.current_sales_rate)}` : "-",
                });
            } else {
                result.push({
                    key: index + 1,
                    fulfillment_channel_title: item.fulfillment_channel_title,
                    children: [
                        {
                            key: `INNER-${index + 1}`,
                            fulfillment_channel_title: (
                                <>
                                    <EnterOutlined style={{ transform: "scaleX(-1)", opacity: "0.5" }} /> {item.marketplace}
                                </>
                            ),
                            past_unit_price: item.past_unit_price ? (
                                <>
                                    {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.past_original_unit_price)} {item.currency}{" "}
                                    {item.currency !== auth.user.currency ? (
                                        <b>
                                            ({beautifyNumber(item.past_unit_price)} {auth.user.currency}){" "}
                                        </b>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                "-"
                            ),
                            past_revenue: item.past_revenue ? (
                                <>
                                    {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.past_original_revenue)} {item.currency}{" "}
                                    {item.currency !== auth.user.currency ? (
                                        <b>
                                            ({beautifyNumber(item.past_revenue)} {auth.user.currency}){" "}
                                        </b>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                "-"
                            ),
                            past_sales_rate: item.past_sales_rate ? `${beautifyNumber(item.past_sales_rate)}` : "-",
                            current_unit_price: item.current_unit_price ? (
                                <>
                                    {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.current_original_unit_price)} {item.currency}{" "}
                                    {item.currency !== auth.user.currency ? (
                                        <b>
                                            ({beautifyNumber(item.current_unit_price)} {auth.user.currency}){" "}
                                        </b>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                "-"
                            ),
                            current_revenue: item.current_revenue ? (
                                <>
                                    {getSymbolFromCurrency(item.currency)} {beautifyNumber(item.current_original_revenue)} {item.currency}{" "}
                                    {item.currency !== auth.user.currency ? (
                                        <b>
                                            ({beautifyNumber(item.current_revenue)} {auth.user.currency}){" "}
                                        </b>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                "-"
                            ),
                            current_sales_rate: item.current_sales_rate ? `${beautifyNumber(item.current_sales_rate)}` : "-",
                        },
                    ],
                });
            }

            return result;
        }, []);
        return transformedData;
    };

    const handleItemClick = (channel_sku) => {
        filterForm.setFieldsValue({ sellersku: "ALL" });
        setSellerSkuFilter("");
        setSelectedItem(channel_sku);
        dashboard.getProductInfoDetailsByChannelSKU(channel_sku, timeframe, marketplaceFilter, "");
    };

    const renderItem = (item, index) => {
        const isSelected = selectedItem === item;
        return (
            <List.Item key={`ITEM-${index}`} onClick={() => handleItemClick(item)} className={isSelected ? "bg-primary text-white" : ""}>
                {item}
            </List.Item>
        );
    };

    const changeMarketplace = (val) => {
        setMarketplaceFilter(val ? val.join() : "");
        dashboard.getProductInfoDetailsByChannelSKU(selectedItem, timeframe, val ? val.join() : "", sellerSkuFilter);
    };

    const changeSellerSKU = (val) => {
        setSellerSkuFilter(val ? val : "");
        dashboard.getProductInfoDetailsByChannelSKU(selectedItem, timeframe, marketplaceFilter, val ? val : "");
    };

    useEffect(() => {
        dashboard.getProductInfoChannelSKUs();
        dashboard.getMarketplaces(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dashboard.productInfoSKUList?.result) {
            setProductList(dashboard.productInfoSKUList?.result);
            setFilteredList(dashboard.productInfoSKUList?.result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.productInfoSKUList?.result]);

    useEffect(() => {
        setFilteredList(productList.filter((item) => item.toLowerCase().includes(filter.toLowerCase())));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <>
            <Row gutter={24}>
                <Col id="ChannelSKUId" span={3}>
                    <>
                        <CustomInput onChange={channelSKUFilter} placeholder="Search Here" className="mb-2 p-1H7 fs-11" />
                        <List bordered loading={dashboard.isFetchingData} style={{ maxHeight: "100vh", overflowY: "scroll", background: "white" }} dataSource={filteredList} renderItem={renderItem}></List>
                    </>
                </Col>
                <Col span={21}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Radio.Group optionType="button" onChange={changeTimeframe} className="w-100" buttonStyle="solid" size="small" defaultValue="today">
                                <Radio.Button value="today">Today</Radio.Button>
                                <Radio.Button value="weekly">Weekly</Radio.Button>
                                <Radio.Button value="monthly">Monthly</Radio.Button>
                                <Radio.Button value="quarterly">Quarterly</Radio.Button>
                                <Radio.Button value="yearly">Yearly</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Form form={filterForm} initialValues={{ sellersku: undefined }} layout="inline" className="w-100">
                            <Col className="mt-2 remove-right-gutter" xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                                <Form.Item className="p-0 m-0" name="marketplaces">
                                    <Select onChange={changeMarketplace} className="w-100 my-custom-multiselect" options={dashboard.productInfoMPList} mode="multiple" maxTagCount="responsive" allowClear placeholder="Select Marketplaces" />
                                </Form.Item>
                            </Col>
                            <Col className="mt-2 remove-right-gutter" xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                                <Form.Item className="p-0 m-0" name="sellersku">
                                    <Select onChange={changeSellerSKU} allowClear className="w-100" placeholder="Select Seller SKU" options={dashboard.productInfoChannelSKUDetail.all_sku} />
                                </Form.Item>
                            </Col>
                            <Col className="mt-2 my-custom-btn" xs={24} sm={24} md={24} lg={2} xl={2} xxl={2}>
                                <Button block htmlType="submit" size="large" loading={dashboard.isFetchingData} className="w-100 bg-primary text-white btn-custom-height-1">
                                    {screens.xs === false && screens.xxl === false ? <ReloadOutlined title="Refresh" /> : "Refresh"}
                                </Button>
                            </Col>
                        </Form>
                    </Row>
                    <Row className="mt-1" gutter={24}>
                        <Col className="mt-1 my-custom-table custom-table-cell-left" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <CustomTable
                                style={{ minHeight: 500, maxHeight: 800, overflowY: "scroll" }}
                                loading={dashboard.isFetchingData}
                                data={convertJSONToData(dashboard.productInfoChannelSKUDetail?.combined_data || [])}
                                columns={projectColumns}
                                pagination={false}
                                border={true}
                                size="small"
                                scroll={{ x: "100%" }}
                                title={dashboard.productInfoChannelSKUDetail.description ? <p className="w-100 p-2 m-0 bg-secondary">{dashboard.productInfoChannelSKUDetail.description}</p> : ""}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default inject("dashboard", "auth")(observer(Dashboard));
