// import axios from "axios";
import axios from "./Provider/AuthProvider";

class ManageUserService {
    fetchUserService = async ({ page, role, pageSize, search, sort }) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/users`;
        if (sort) {
            url += `?sortBy=${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`;
        } else {
            url += `?sortBy=createdAt:desc`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (pageSize) {
            url += `&limit=${pageSize}`;
        }
        if (role) {
            url += `&role=${role === "customer" ? "customer_user" : "sm_user"}`;
        }
        if (search && search !== "undefined") {
            url += `&search=${search}`;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    addUserService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/auth/steelmain`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    editUserService = async (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/users/${id}`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    deleteUserService = async (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${process.env.REACT_APP_API_ENDPOINT}/v1/users/${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    deleteCustomerService = async (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${process.env.REACT_APP_API_ENDPOINT}/v1/users/${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default new ManageUserService();
