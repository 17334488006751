import { makeAutoObservable } from "mobx";

class RouterStore {
    location = {};
    param = {};
    history = {};

    constructor() {
        makeAutoObservable(this);
    }

    setRoute(location, param, history) {
        this.location = location;
        this.param = param;
        this.history = history;
    }
}

export default RouterStore;
