import React from "react";
import { Navigate } from "react-router-dom";
import { getItem } from "./helper/localStorage";

const NormalRoute = ({ children }) => {
    let lData = getItem("sm-user-info");
    let token = getItem("sm-user-token");

    if (token && lData && Object.keys(lData).includes("id")) {
        return <Navigate to="/dashboard" replace />;
    }
    return children;
};

export default NormalRoute;
