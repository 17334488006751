import { ArrowLeftOutlined, EyeFilled } from "@ant-design/icons";
import { Button, Col, Row, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../common/modal";
import { CustomTable } from "../../common/tables";

const ArchiveProject = ({ project }) => {
    const navigate = useNavigate();
    const [customerDetailModal, setCustomerDetailModal] = useState(false);
    const [popUpData, setPopUpData] = useState({});

    const columns = [
        {
            title: "Project ID",
            dataIndex: "project_id",
            width: 120,
        },
        {
            title: "Project Name",
            dataIndex: "project_name",
            width: 150,
        },
        // {
        //     title: "Manufacturer",
        //     dataIndex: "manufacturer",
        //     width: 150,e
        // },
        {
            title: "Date",
            dataIndex: "createdAt",
            width: 80,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 50,
            render: (_, record) => {
                return (
                    <Tooltip title="Details">
                        <Button
                            className="action-btn-view mr-1"
                            onClick={() => {
                                setPopUpData(record);
                                setCustomerDetailModal(true);
                            }}
                            type="primary"
                            shape="squre"
                            icon={<EyeFilled />}
                        />
                    </Tooltip>
                );
            },
        },
    ];

    const onChange = (pagination) => {
        project.getAllArchiveProjects({ page: pagination.current });
    };

    useEffect(() => {
        project.getAllArchiveProjects({ page: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Row gutter={24}>
                <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        data={project.archiveProjectData || []}
                        columns={columns}
                        onChange={onChange}
                        loading={project.isFetchingData}
                        border={true}
                        pagination={{ total: project.totalArchiveProjects, current: project.pageArchive }}
                        scroll={{ x: 550 }}
                        title={
                            <span className="archive-arrow">
                                <ArrowLeftOutlined onClick={() => navigate("/projects")} />
                                <span className="p-0 m-0">Archive Project List</span>
                            </span>
                        }
                    />
                </Col>
            </Row>
            <CustomModal visibility={customerDetailModal} handleCancel={setCustomerDetailModal} className="detail-customer-modal" centered title="View Customer Detail">
                <table className="detail-table">
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>Project Id</td>
                            <td>{popUpData.id}</td>
                        </tr>
                        <tr>
                            <td>Project Name</td>
                            <td>{popUpData.project_name}</td>
                        </tr>
                        <tr>
                            <td>Pipe Details</td>
                            <td>{popUpData.pipe_size}</td>
                        </tr>
                        <tr>
                            <td>Rated Pressure</td>
                            <td>{popUpData.rated_pressure}</td>
                        </tr>
                        <tr>
                            <td>Applicable Standards</td>
                            <td>{popUpData.applicable_standards}</td>
                        </tr>
                    </tbody>
                </table>
            </CustomModal>
        </>
    );
};

export default inject("project")(observer(ArchiveProject));
