import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import logo from "../../assets/logo/logo.png";
// import logo2 from "../../assets/logo/logo2.png";
import { getItem } from "../../helper/localStorage";
import { IconDashboard, IconProjects } from "./icons/icon";
// import { IconDashboard, IconCustomers, IconProjects, IconSetting } from "./icons/icon";
const { Sider } = Layout;

let userInfo;
export default function MySideBar(props) {
    const items = [
        {
            key: "SalesDashboard",
            icon: <IconDashboard />,
            label: <NavLink to="/dashboard">Sales Dashboard</NavLink>,
            role: ["systemAdmin", "merchantAdmin"],
        },
        {
            key: "ChannelSales",
            icon: <IconDashboard />,
            label: <NavLink to="/channel-sales">Channel Sales</NavLink>,
            role: ["systemAdmin", "merchantAdmin"],
        },
        {
            key: "ProductInfo",
            icon: <IconProjects />,
            label: <NavLink to="/product-info">Product Info</NavLink>,
            role: ["systemAdmin", "merchantAdmin"],
        },
        {
            key: "SalesAlert",
            icon: <IconDashboard />,
            label: <NavLink to="/sales-alert">Sales Alert</NavLink>,
            role: ["systemAdmin", "merchantAdmin"],
        },
        // {
        //     key: "AppSettings",
        //     icon: <IconSetting />,
        //     label: <NavLink to="/dashboard">App Settings</NavLink>,
        //     role: ["systemAdmin", "merchantAdmin"],
        // },
        // {
        //     key: "ChannelConfig",
        //     icon: <IconSetting />,
        //     label: <NavLink to="/dashboard">Channel Config</NavLink>,
        //     role: ["systemAdmin", "merchantAdmin"],
        // },
        // {
        //     key: "Users",
        //     icon: <IconCustomers />,
        //     label: <NavLink to="/dashboard">Users</NavLink>,
        //     role: ["systemAdmin", "merchantAdmin"],
        // },
    ];

    try {
        userInfo = getItem("sm-user-info");
    } catch (error) {}

    const { pathname } = useLocation();
    const [brkPoint, setBrkPoint] = useState(false);
    const allItems = items.filter((elm) => elm.role.includes(userInfo.role));
    let selectedKey = allItems.find((o) => o.to === pathname);

    if (!selectedKey) {
        if (pathname === "/dashboard") {
            selectedKey = "SalesDashboard";
        } else if (pathname === "/channel-sales") {
            selectedKey = "ChannelSales";
        } else if (pathname === "/product-info") {
            selectedKey = "ProductInfo";
        } else if (pathname === "/sales-alert") {
            selectedKey = "SalesAlert";
        } else if (pathname === "/archive-project") {
            selectedKey = "Projects";
        } else if (pathname.includes("/projects")) {
            selectedKey = "Projects";
        } else if (pathname === "/manage-users") {
            selectedKey = "ManageUsers";
        } else {
            selectedKey = "Dashboard";
        }
    } else {
        selectedKey = selectedKey.key;
    }

    return (
        <>
            <Sider
                collapsed={props.collapsed}
                onBreakpoint={(data) => {
                    setBrkPoint(data);
                }}
                breakpoint="lg"
                collapsedWidth={brkPoint ? 0 : 80}
                theme="light"
                width={250}
                style={{
                    boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.16)",
                }}
            >
                <div
                    className="logo"
                    style={{
                        width: "100%",
                        height: "5.5em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.5em",
                    }}
                >
                    {/* <img alt="PM" src={props.collapsed ? logo2 : logo} style={{ height: "100%" }} /> */}
                    <h2 className="text-primary" style={{ fontSize: "2.8rem", letterSpacing: -3 }}>
                        {props.collapsed ? "SV" : "Seller Viz"}
                    </h2>
                </div>
                <Menu className={props.collapsed ? "mt-2 colsp" : "mt-2"} theme="light" items={items.filter((elm) => elm.role.includes(userInfo.role))} mode="inline" selectedKeys={selectedKey}></Menu>
            </Sider>
        </>
    );
}
