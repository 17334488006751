import { DeleteFilled, EditFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Popconfirm, Radio, Row, Select, Tabs, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getItem } from "../../../helper/localStorage";
import CustomInput from "../../common/index";
import CustomPassword from "../../common/indexPassword";
import { CustomModal } from "../../common/modal";
import { CustomTable } from "../../common/tables";

let userInfo;
const { Option } = Select;

const Users = ({ user, customer }) => {
    const [addUserForm] = Form.useForm();
    const [CUTab, setCUTab] = useState(false);
    const [showExtra, setShowExtra] = useState(false);
    const [search, setSearch] = useState("");

    try {
        userInfo = getItem("sm-user-info");
    } catch (error) {}

    const onTabChange = (key) => {
        if (key === 2) {
            setCUTab(true);
            customer.getAllCustomerOnly();
            customer.getAllCustomerUsers();
        } else {
            setCUTab(false);
            user.getAllUsers({ page: 1 });
        }
    };

    const showHeaders = ({ target: { checked } }) => {
        setShowExtra(checked);
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "id",
            width: 100,
            render: (i, j, k) => {
                return user.page * user.pageSize - user.pageSize + (k + 1);
            },
        },
        {
            title: "Username",
            dataIndex: "username",
            width: 120,
            sorter: (a, b) => a.username - b.username,
        },
        {
            title: "Role",
            dataIndex: "role",
            width: 120,
            render: (_, record) => (record.role === "admin" ? "ADMIN" : "USER"),
            sorter: (a, b) => a.role - b.role,
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            width: 120,
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 100,
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title="Details">
                            <Button
                                className="action-btn-edit mr-1"
                                type="text"
                                shape="squre"
                                icon={<EditFilled />}
                                onClick={() => {
                                    record.password = "";
                                    addUserForm.setFieldsValue(record);
                                    user.addUserModal(true, true);
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Delete">
                            <Popconfirm title="Are you sure to delete this User?" onConfirm={() => deleteUser(record.id)} okText="Yes" cancelText="No">
                                <Button icon={<DeleteFilled />} className="action-btn-delete mr-1" type="primary" danger shape="squre" />
                            </Popconfirm>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const customerUserColumns = [
        {
            title: "Username",
            dataIndex: "username",
            width: 120,
            sorter: (a, b) => a.username - b.username,
            filteredValue: [search],
            onFilter: (value, record) => {
                return (
                    String(record.username).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.customer.name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.createdAt).toLowerCase().includes(value.toLowerCase())
                );
            },
        },
        {
            title: "Customer",
            dataIndex: "customer",
            width: 120,
            sorter: (a, b) => a.customer.name - b.customer.name,
            render: (val, _) => (val.name ? val.name : "-"),
        },
        {
            title: "Email",
            dataIndex: "email",
            width: 100,
            sorter: (a, b) => a.email - b.email,
            render: (email, _) => (email ? email : "-"),
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            width: 120,
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: (dt) => moment(dt).format("DD-MM-YYYY"),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 120,
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title="Details">
                            <Button
                                className="action-btn-edit mr-1"
                                type="text"
                                shape="squre"
                                icon={<EditFilled />}
                                onClick={() => {
                                    record.password = "";
                                    record = { ...record, id: record._id };
                                    addUserForm.setFieldsValue(record);
                                    user.addCUserModal(true, true);
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Delete">
                            <Popconfirm title="Are you sure to delete this User?" onConfirm={() => deleteUser(record._id, true)} okText="Yes" cancelText="No">
                                <Button icon={<DeleteFilled />} className="action-btn-delete mr-1" type="primary" danger shape="squre" />
                            </Popconfirm>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const handleFinish = async (values) => {
        values = { ...values, name: values.username };
        if (userInfo.role === "customer") {
            values.role = "customer_user";
            values.customer_id = userInfo.id;
            await customer.addCustomer(values, addUserForm, true, false);
            return false;
        } else {
            if (CUTab) {
                values.role = "customer_user";
                values = { ...values, is_editable: showExtra };

                await customer.addCustomer(values, addUserForm, true, true);
                return false;
            }
            await user.addUser(values, addUserForm);
        }
    };

    const handleUpdate = async (values) => {
        let id = values.id;
        delete values.id;
        delete values.username;
        delete values.email;

        if (userInfo.role === "customer") {
            await customer.editCustomer(id, values, "customer");
            return false;
        } else {
            if (CUTab) {
                await customer.editCustomer(id, values, "customer_user");
                return false;
            }
        }
        await user.editUser(id, values);
    };

    const deleteUser = async (id, customers = false) => {
        if (userInfo.role === "customer") {
            await customer.deleteCustomer(id, "customer");
        } else {
            if (customers) {
                await customer.deleteCustomer(id, "customer_user");
                return;
            }
            await user.deleteUser(id);
        }
    };

    const onChange = (pagination, _, sort) => {
        if (!CUTab) {
            if (sort && Object.keys(sort) && Object.keys(sort).length > 0) {
                user.getAllUsers({ page: pagination.current, pageSize: pagination.pageSize, sort });
                return;
            }
            user.getAllUsers({ page: pagination.current, pageSize: pagination.pageSize });
        }
    };

    useEffect(() => {
        user.getAllUsers({ page: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tabs = [
        {
            key: 1,
            label: `Steel Main Users`,
            children: (
                <>
                    <Row gutter={24}>
                        <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <CustomTable
                                data={user.userData || []}
                                loading={user.isFetchingData}
                                columns={columns}
                                border={true}
                                onChange={onChange}
                                pagination={{ total: user.totalUser, current: user.page }}
                                scroll={{ x: 560 }}
                                title="User List"
                                children={
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Item className="p-0 m-0 mr-2">
                                            <Input
                                                className="p-1"
                                                placeholder="Search Users"
                                                prefix={<SearchOutlined />}
                                                onKeyUp={async (e) => {
                                                    await user.getAllUsers({ page: 1, pageSize: 10, search: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                        <Button
                                            className="bg-primary"
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            size="large"
                                            loading={user.isFetchingData}
                                            onClick={async () => {
                                                addUserForm.resetFields();
                                                user.addUserModal(true);
                                            }}
                                        >
                                            Add New User
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                    <CustomModal visibility={user.showUserModal} handleCancel={user.addUserModal} className="add-customer-modal" centered title={user.isEdit ? "Edit User" : "Add New User"}>
                        <div className="add-user-modal">
                            <Form layout="vertical" id="AddCustomerUserForm" initialValues={{ role: "sm_user" }} form={addUserForm} onFinish={user.isEdit ? handleUpdate : handleFinish}>
                                {user.isEdit ? (
                                    <Form.Item label="id" name="id" hidden={true}>
                                        <CustomInput />
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Username",
                                        },
                                    ]}
                                >
                                    <CustomInput disabled={user.isEdit ? true : false} className="w-100 p-1H7 fs-1" inputtype="alphanumeric" />
                                </Form.Item>
                                <Form.Item
                                    label="Email Address"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Email",
                                        },
                                        {
                                            type: "email",
                                            message: "Please enter valid Email",
                                        },
                                    ]}
                                >
                                    <CustomInput disabled={user.isEdit ? true : false} className="w-100 p-1H7 fs-1" />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Password",
                                        },
                                    ]}
                                >
                                    <CustomPassword autoComplete="new-password" className="w-100 p-1H7 fs-1" />
                                </Form.Item>
                                {userInfo.role === "admin" && user.isEdit === false ? (
                                    <Form.Item
                                        label="Select User Role"
                                        name="role"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Role",
                                            },
                                        ]}
                                    >
                                        <Radio.Group className="user-type">
                                            <Radio.Button className="w-50 text-center" value="admin">
                                                Admin
                                            </Radio.Button>
                                            <Radio.Button className="w-50 text-center" value="sm_user">
                                                User
                                            </Radio.Button>
                                        </Radio.Group>
                                        {/* <Radio.Group className="d-flex">
                                    <Radio className="w-20 text-center fs-11" value="admin">
                                        Admin
                                    </Radio>
                                    <Radio className="w-20 text-center fs-11" value="sm_user">
                                        User
                                    </Radio>
                                </Radio.Group> */}
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                <Button block htmlType="submit" loading={user.isFetchingData} size="large" className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                                    {user.isEdit ? "Update" : "Add"}
                                </Button>
                            </Form>
                        </div>
                    </CustomModal>
                </>
            ),
        },
        {
            key: 2,
            label: `Customer Users`,
            children: (
                <>
                    <Row gutter={24}>
                        <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <CustomTable
                                data={customer.customerUsers || []}
                                loading={customer.isFetchingData}
                                columns={customerUserColumns}
                                border={true}
                                onChange={!CUTab ? onChange : null}
                                pagination={true}
                                scroll={{ x: 580 }}
                                title="Customer User List"
                                children={
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Item className="p-0 m-0 mr-2">
                                            <Input
                                                className="p-1"
                                                placeholder="Search Customer Users"
                                                prefix={<SearchOutlined />}
                                                onKeyUp={async ({ target: { value } }) => {
                                                    setSearch(value);
                                                }}
                                            />
                                        </Form.Item>
                                        <Button
                                            className="bg-primary"
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            size="large"
                                            loading={user.isFetchingData}
                                            onClick={() => {
                                                addUserForm.resetFields();
                                                user.addCUserModal(true);
                                            }}
                                        >
                                            Add New Customer User
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                    <CustomModal visibility={user.showCUserModal} handleCancel={user.addCUserModal} className="add-customer-modal" centered title={user.isCEdit ? "Edit Customer User" : "Add New Customer User"}>
                        <div className="add-user-modal">
                            <Form layout="vertical" initialValues={{ role: "sm_user" }} id="AddCustomerUserForm" form={addUserForm} onFinish={user.isCEdit ? handleUpdate : handleFinish}>
                                {user.isCEdit ? (
                                    <Form.Item label="id" name="id" hidden={true}>
                                        <CustomInput />
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                {CUTab ? (
                                    <Form.Item
                                        label="Select Customer"
                                        name="customer_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Select Customer",
                                            },
                                        ]}
                                    >
                                        <Select className="w-100" disabled={user.isCEdit ? true : false}>
                                            {customer.customerDataDD?.map((elm) => (
                                                <Option key={elm.key} value={elm.id}>
                                                    {elm.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                <Form.Item
                                    label={CUTab ? "Customer Username" : "Username"}
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Username",
                                        },
                                    ]}
                                >
                                    <CustomInput disabled={user.isCEdit ? true : false} className="w-100 p-1H7 fs-1" inputtype="alphanumeric" />
                                </Form.Item>

                                <Form.Item
                                    label="Email Address"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            email: true,
                                            message: "Please enter Email",
                                        },
                                    ]}
                                >
                                    <CustomInput disabled={user.isCEdit ? true : false} className="w-100 p-1H7 fs-1" />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={
                                        user.isCEdit
                                            ? []
                                            : [
                                                  {
                                                      required: true,
                                                      message: "Please enter Password",
                                                  },
                                              ]
                                    }
                                >
                                    <CustomPassword autoComplete="new-password" className="w-100 p-1H7 fs-1" />
                                </Form.Item>

                                {CUTab ? (
                                    <></>
                                ) : userInfo.role === "admin" && user.isCEdit === false && !CUTab ? (
                                    <Form.Item
                                        label="Select User Role"
                                        name="role"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Role",
                                            },
                                        ]}
                                    >
                                        <Radio.Group className="user-type">
                                            <Radio.Button className="w-50 text-center" value="admin">
                                                Admin
                                            </Radio.Button>
                                            <Radio.Button className="w-50 text-center" value="sm_user">
                                                User
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                {CUTab ? (
                                    <Form.Item name="is_editable" className="mb-0" valuePropName="checked">
                                        <Checkbox onChange={showHeaders}>Allow this user to edit details</Checkbox>
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                <Button block htmlType="submit" loading={CUTab ? customer.isFetchingData : user.isFetchingData} size="large" className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                                    {user.isCEdit ? "Update" : "Add"}
                                </Button>
                            </Form>
                        </div>
                    </CustomModal>
                </>
            ),
        },
    ];

    return userInfo.role === "admin" ? (
        <Tabs defaultActiveKey="1" items={tabs} onChange={onTabChange} />
    ) : (
        <>
            <Row gutter={24}>
                <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        data={user.userData || []}
                        loading={user.isFetchingData}
                        columns={columns}
                        border={true}
                        onChange={onChange}
                        pagination={{ total: user.totalUser, current: user.page }}
                        scroll={{ x: 580 }}
                        title="User List"
                        children={
                            <div className="d-flex justify-content-center align-items-center">
                                <Form.Item className="p-0 m-0 mr-2">
                                    <Input
                                        className="p-1"
                                        placeholder="Search Users"
                                        prefix={<SearchOutlined />}
                                        onKeyUp={async (e) => {
                                            await user.getAllUsers({ page: 1, pageSize: 10, search: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Button
                                    className="bg-primary"
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="large"
                                    loading={user.isFetchingData}
                                    onClick={async () => {
                                        addUserForm.resetFields();
                                        user.addUserModal(true);
                                    }}
                                >
                                    Add New User
                                </Button>
                            </div>
                        }
                    />
                </Col>
            </Row>
            <CustomModal visibility={user.showUserModal} handleCancel={user.addUserModal} className="add-customer-modal" centered title={user.isEdit ? "Update User" : "Add New User"}>
                <div className="add-user-modal">
                    <Form layout="vertical" initialValues={{ role: "sm_user" }} form={addUserForm} onFinish={user.isEdit ? handleUpdate : handleFinish}>
                        {user.isEdit ? (
                            <Form.Item label="id" name="id" hidden={true}>
                                <CustomInput />
                            </Form.Item>
                        ) : (
                            <></>
                        )}
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Username",
                                },
                            ]}
                        >
                            <CustomInput disabled={user.isEdit ? true : false} className="w-100 p-1H7 fs-1" inputtype="alphanumeric" />
                        </Form.Item>

                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Email",
                                },
                                {
                                    type: "email",
                                    message: "Please enter valid Email",
                                },
                            ]}
                        >
                            <CustomInput disabled={user.isEdit ? true : false} className="w-100 p-1H7 fs-1" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={
                                user.isEdit
                                    ? []
                                    : [
                                          {
                                              required: true,
                                              message: "Please enter Password",
                                          },
                                      ]
                            }
                        >
                            <CustomPassword autoComplete="new-password" className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        {userInfo.role === "admin" && user.isEdit === false ? (
                            <Form.Item
                                label="Select User Role"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Role",
                                    },
                                ]}
                            >
                                <Radio.Group className="user-type">
                                    <Radio.Button className="w-50 text-center" value="admin">
                                        Admin
                                    </Radio.Button>
                                    <Radio.Button className="w-50 text-center" value="sm_user">
                                        User
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        ) : (
                            <></>
                        )}
                        <Form.Item name="is_editable" className="mb-0" valuePropName="checked">
                            <Checkbox onChange={showHeaders}>Allow this user to edit details</Checkbox>
                        </Form.Item>
                        <Button block htmlType="submit" loading={user.isFetchingData} size="large" className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                            {user.isEdit ? "Update" : "Add"}
                        </Button>
                    </Form>
                </div>
            </CustomModal>
        </>
    );
};

export default inject("user", "customer")(observer(Users));
