/* eslint-disable */
import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import { getItem } from "../../helper/localStorage";
import ManageUserService from "../../services/ManageUser.service";

class ManageUserStore {
    isFetchingData = false;
    showUserModal = false;
    isEdit = false;
    userData = [];
    totalUser = 0;
    page = 1;
    pageSize = 10;
    showCUserModal = false;
    isCEdit = false;

    constructor(allStores) {
        makeAutoObservable(this);
        this.allStores = allStores;
    }

    reset = () => {
        this.isFetchingData = false;
        this.userData = [];
        this.totalUser = 0;
        this.showUserModal = false;
        this.isEdit = false;
        this.page = 1;
        this.pageSize = 10;
        this.showCUserModal = false;
        this.isCEdit = false;
    };

    getAllUsers = async ({ page = 1, pageSize = 10, search = null, sort = null }) => {
        try {
            const { role } = getItem("sm-user-info");
            runInAction(() => {
                this.userData = [];
                this.isFetchingData = true;
            });
            const data = await ManageUserService.fetchUserService({ page, role, pageSize, search, sort });
            if (data.status < 400) {
                if (data.data.result.totalResults > 0) {
                    let addKey = data.data.result.data.map((elm) => {
                        return { key: elm.id, ...elm };
                    });

                    this.userData = addKey;
                    this.totalUser = data.data.result.totalResults;
                    this.page = data.data.result.page;
                    this.pageSize = data.data.result.limit;
                }
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    addUser = async (values, form) => {
        try {
            this.isFetchingData = true;
            const data = await ManageUserService.addUserService(values);

            if (data.status < 400) {
                message.success(data.data.message);
                form.resetFields();
                this.addUserModal(false);
                this.getAllUsers({ page: 1 });
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    editUser = async (id, values) => {
        try {
            this.isFetchingData = true;
            const data = await ManageUserService.editUserService(id, values);

            if (data.status < 400) {
                message.success(data.data.message);
                this.addUserModal(false);
                this.getAllUsers({ page: 1 });
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    deleteUser = async (id) => {
        try {
            this.isFetchingData = true;
            const data = await ManageUserService.deleteUserService(id);

            if (data.status < 400) {
                message.success(data.data.message);
                this.getAllUsers({ page: 1 });
            } else {
                this.isFetchingData = false;
                message.error(data.response.data.message || "Something went Wrong.!");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    addUserModal = async (visibility, edit = false) => {
        runInAction(() => {
            this.showUserModal = visibility;
            this.isEdit = edit;
        });
    };

    addCUserModal = async (visibility, edit = false) => {
        runInAction(() => {
            this.showCUserModal = visibility;
            this.isCEdit = edit;
        });
    };
}

export default ManageUserStore;
