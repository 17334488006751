import React from "react";
import { Navigate } from "react-router-dom";
import { getItem } from "./helper/localStorage";

export const ProtectRoute = ({ children, normal = false, role }) => {
  try {
    let lData = getItem("sm-user-info");
    let token = getItem("sm-user-token");

    if (token && lData && Object.keys(lData).includes("username")) {
      if (normal) {
        return <Navigate to="/dashboard" replace />;
      } else {
        if (role.includes(lData.role)) {
          return <>{children}</>;
        } else {
          return <Navigate to="/dashboard" />;
        }
      }
    }

    return <Navigate to="/" />;
  } catch (error) {
    return <Navigate to="/" />;
  }
};
