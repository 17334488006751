import { Col, Row, Spin, Radio } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Cosmatics } from "./cosmatics";
import { ShoppingOutlined, ShopOutlined } from "@ant-design/icons";

// import { useNavigate } from "react-router-dom";
// import { getItem } from "../../../helper/localStorage";
import { CustomTable } from "../../common/tables";
// import { IconCustomers, IconProjects } from "../../sidebar/icons/icon";

// let userInfo;
// let Main_User;

const Dashboard = ({ dashboard }) => {
    // const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const projectColumns = [
        {
            title: "Channel SKU",
            dataIndex: "channel_sku",
            width: "15%",
        },
        {
            title: "Description",
            dataIndex: "description",
            width: "70%",
        },
        {
            title: "Unit Sold",
            dataIndex: "sold_unit",
            align: "center",
            width: "15%",
        },
    ];

    // try {
    //     userInfo = getItem("sm-user-info");
    //     Main_User = ["systemAdmin", "merchantAdmin"].includes(userInfo.role) ? true : false;
    // } catch (error) {}

    useEffect(() => {
        dashboard.getDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeTimeframe = (e) => {
        dashboard.getDashboard(e.target.value);
    };

    const returnSales = (count = null) => {
        if (dashboard.dashboardData.sales) {
            const sortedData = dashboard.dashboardData.sales.sort((a, b) => b.sold_unit - a.sold_unit);
            if (count) {
                setSales(sortedData.slice(0, 5).map((record, index) => ({ ...record, key: `${record.channel_sku}${index}` })));
            } else {
                setSales(sortedData.map((record, index) => ({ ...record, key: `${record.channel_sku}${index}` })));
            }
        }
    };

    useEffect(() => {
        returnSales(5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.dashboardData.sales]);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Radio.Group optionType="button" onChange={changeTimeframe} className="w-100" buttonStyle="solid" size="small" defaultValue="today">
                        <Radio.Button value="today">Today</Radio.Button>
                        <Radio.Button value="weekly">Weekly</Radio.Button>
                        <Radio.Button value="monthly">Monthly</Radio.Button>
                        <Radio.Button value="all">All</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Row gutter={24}>
                {/* <Col className="mt-2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <div className="dashboard-welcome">
                        <p className="welcome-name">Welcome Back, {userInfo.name}</p>
                        <p className="welcome-quote">You have accessed the Steel Mains RFID identification application</p>
                        <p className="welcome-date">{moment().format("DD MMM YYYY")}</p>
                    </div>
                </Col> */}
                <Col className="mt-2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Cosmatics
                        title="Total Orders"
                        counts={
                            !dashboard.isFetchingData && (dashboard.dashboardData.total_orders || dashboard.dashboardData.total_orders === 0) ? (
                                <p className="cosmatic-counts">{Intl.NumberFormat("en-US").format(dashboard.dashboardData.total_orders)}</p>
                            ) : (
                                <Spin className="pt-1" spinning={true} />
                            )
                        }
                        icon={<ShoppingOutlined style={{ fontSize: "26px" }} />}
                    />
                </Col>
                <Col className="mt-2" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Cosmatics
                        title="Total Items"
                        counts={
                            !dashboard.isFetchingData && (dashboard.dashboardData.total_sold_units || dashboard.dashboardData.total_sold_units === 0) ? (
                                <p className="cosmatic-counts">{Intl.NumberFormat("en-US").format(dashboard.dashboardData.total_sold_units)}</p>
                            ) : (
                                <Spin className="pt-1" spinning={true} />
                            )
                        }
                        icon={<ShopOutlined style={{ fontSize: "26px" }} />}
                    />
                </Col>
            </Row>
            <Row className="mt-1" gutter={24}>
                <Col className="mt-1 my-custom-table" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        style={{ minHeight: 400, maxHeight: 650, overflowY: "scroll" }}
                        loading={dashboard.isFetchingData}
                        data={sales}
                        columns={projectColumns}
                        pagination={false}
                        size="small"
                        scroll={{ x: "100%" }}
                        title="Sales Data"
                        children={
                            <p className="cursor-pointer mr-2" onClick={() => returnSales(null)}>
                                View All
                            </p>
                        }
                    />
                </Col>
            </Row>
        </>
    );
};

export default inject("dashboard")(observer(Dashboard));
