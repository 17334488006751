import { DownOutlined, LockOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Layout } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import CustomPassword from "../common/indexPassword";
import CustomInput from "../common/index";
import { CustomModal } from "../common/modal";

const { Header } = Layout;

function MyHeader(props) {
    const [changePasswordForm] = Form.useForm();

    const items = [
        {
            key: "1",
            icon: <LockOutlined />,
            label: (
                <div
                    onClick={() => {
                        changePasswordForm.setFieldsValue({ ...props.auth.user });
                        props.auth.openChangePassword(true);
                    }}
                    className="header-action-item"
                >
                    User Profile
                </div>
            ),
        },
        {
            key: "2",
            icon: <PoweroffOutlined />,
            danger: true,
            label: (
                <div onClick={() => props.auth.logout()} className="header-action-item">
                    Logout
                </div>
            ),
        },
    ];

    const handleFinish = async (values) => {
        // delete values.confirm_password;

        if (!values.password) {
            delete values.password;
        }
        await props.auth.resetPassword(values);
        props.auth.setUser({...props.auth, email: values.email, first_name: values.first_name, last_name: values.last_name})
    };

    return (
        <>
            <Header
                theme="light"
                className={`site-layout-sub-header-background ${props.collapsed ? "w_80" : "w_250"}`}
                style={{
                    padding: 0,
                    background: "white",
                }}
            >
                {props.collapsed ? (
                    <MenuUnfoldOutlined
                        style={{
                            marginLeft: "0.85em",
                            padding: "0.4em 0.8em",
                            fontSize: "1.2rem",
                            border: "2px #1d353521 solid",
                            borderRadius: "0.2em",
                        }}
                        onClick={() => props.setCollapsed(!props.collapsed)}
                    />
                ) : (
                    <MenuFoldOutlined
                        style={{
                            marginLeft: "0.85em",
                            padding: "0.4em 0.8em",
                            fontSize: "1.2rem",
                            border: "2px #1d353521 solid",
                            borderRadius: "0.2em",
                        }}
                        onClick={() => props.setCollapsed(!props.collapsed)}
                    />
                )}
                <div className="header-actions">
                    <Dropdown className="px-3" trigger="click" placement="bottomRight" menu={{ items }}>
                        <p className="fs-11 cursor-pointer">
                            {`${props.auth.user.first_name} ${props.auth.user.last_name}`} <DownOutlined />
                        </p>
                    </Dropdown>
                </div>
            </Header>

            <CustomModal visibility={props.auth.showChangePassword} handleCancel={props.auth.openChangePassword} className="add-customer-modal" centered title="Update Profile">
                <div className="add-customer-modal">
                    <Form layout="vertical" id="changePasswordForm" form={changePasswordForm} onFinish={handleFinish}>
                        <Form.Item label="Email" name="email">
                            <CustomInput disabled={true} className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter First Name",
                                },
                            ]}
                        >
                            <CustomInput className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Last Name",
                                },
                            ]}
                        >
                            <CustomInput className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        <Form.Item
                            label="New Password"
                            name="password"
                            rules={[
                                {
                                    min: 8,
                                    message: "Password needs to be at least 8 characters long.",
                                },
                            ]}
                        >
                            <CustomPassword className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        {/* <Form.Item
                            label="Confirm Password"
                            name="confirm_password"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject("New Password & Confirm Password are not same");
                                    },
                                }),
                            ]}
                        >
                            <CustomPassword className="w-100 p-1H7 fs-1" />
                        </Form.Item> */}
                        <Button block htmlType="submit" size="large" loading={props.auth.isFetchingData} className="bg-primary text-white btn-custom-height-1 fs-12">
                            Update
                        </Button>
                    </Form>
                </div>
            </CustomModal>

            <CustomModal visibility={props.auth.showUserProfile} handleCancel={props.auth.openUserProfile} className="add-customer-modal" centered title="User Profile">
                <div className="add-customer-modal">
                    <div className="user-prfile-box">
                        <div className="user-avtar">
                            <UserOutlined />
                        </div>
                        <div className="user-text">
                            <label>Name</label>
                            {props.auth.user.name}
                        </div>
                        <div className="user-text">
                            <label>Username ( Login )</label>
                            {props.auth.user.username}
                        </div>
                        <div className="user-text">
                            <label>Email</label>
                            {props.auth.user.email}
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default inject("auth")(observer(MyHeader));
