import CryptoJS from "crypto-js";

// encrypt function
export const encryptValue = (value) => {
    if (value) {
        const iv = getRandomStr(16);
        const encryptedValue = CryptoJS.AES.encrypt(value, process.env.REACT_APP_ENC_KEY, { iv: CryptoJS.enc.Utf8.parse(iv) }).toString();
        return iv + encryptedValue;
    }
    return value;
};

// decrypt function
export const decryptValue = (value) => {
    try {
        if (value) {
            const iv = value.slice(0, 16);
            value = value.slice(16, value.length);
            const decrypted = CryptoJS.AES.decrypt(value, process.env.REACT_APP_ENC_KEY, { iv: CryptoJS.enc.Utf8.parse(iv) });
            return decrypted.toString(CryptoJS.enc.Utf8);
        }
        return value;
    } catch (error) {
        localStorage.clear();
        window.location.replace("/");
    }
};

export const roundOfAmount = (amt) => {
    return parseFloat(amt / 100).toFixed(2);
};

export const getRndInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
};

export const getRandomStr = (stringLength = 12) => {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let randomstring = "";
    for (let i = 0; i < stringLength; i += 1) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
};

export const NumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
