// import axios from "axios";
import axios from "./Provider/AuthProvider";

class ProjectsService {
    fetchProjectsService = async () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/projects?sortBy=createdAt:desc&archive=false`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchArchiveProjectsService = async ({ page }) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/projects?sortBy=createdAt:desc&archive=true`;
        // if (page) {
        //     url += `&page=${page}`;
        // }
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    getProjectById = async ({ id, custId }) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/projects/${id}`;

        if (custId !== "undefined" && custId !== "null" && custId !== null) {
            url += `?customer_id=${custId}`;
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    getProductsByProjectId = async ({ id, page = 1, pageSize = 10, custId, search, sort }) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/project-items/${id}`;
        if (sort) {
            let finalField;
            if (["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(sort.field)) {
                finalField = `extra.${sort.field}`;
            } else if (["K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(sort.field)) {
                finalField = `customers_extra.${sort.field}`;
            } else {
                finalField = sort.field;
            }
            url += `?sortBy=${finalField}:${sort.order === "ascend" ? "asc" : "desc"}`;
        } else {
            url += `?sortBy=createdAt:desc`;
        }
        if (page) {
            url += `&page=${page}&limit=${pageSize}`;
        }
        if (custId !== "undefined") {
            url += `&customer_id=${custId}`;
        }
        if (search && search !== "undefined") {
            url += `&search=${search}`;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    addProjectsService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/projects`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    updateColumnService = async (data, id) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/projects/${id}`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    updateProjectItemService = async (data, id) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/project-items/${id}`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    updateManyColumns = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/project-items`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    deleteProjectsService = async (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${process.env.REACT_APP_API_ENDPOINT}/v1/projects/${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    archiveProjectsService = async (id) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/projects/archive/${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default new ProjectsService();
