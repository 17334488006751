import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, message, Popconfirm, Row, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { getItem } from "../../../helper/localStorage";
import CustomInput from "../../common/index";
import CustomPassword from "../../common/indexPassword";
import { CustomModal } from "../../common/modal";
import { CustomTable } from "../../common/tables";

const Customers = ({ customer }) => {
    const [customerDetailModal, setCustomerDetailModal] = useState(false);
    const [popUpData, setPopUpData] = useState({});
    const [showExtra, setShowExtra] = useState(false);
    const [addCustomerForm] = Form.useForm();

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "id",
            width: 100,
            render: (i, j, k) => {
                return customer.page * customer.pageSize - customer.pageSize + (k + 1);
            },
            // sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Name",
            dataIndex: "name",
            width: 100,
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Company",
            dataIndex: "company",
            width: 150,
            render: (_, record) => (record.company ? record.company : "-"),
            sorter: (a, b) => a.company - b.company,
        },
        {
            title: "Customer Username",
            dataIndex: "username",
            width: 150,
            sorter: (a, b) => a.username - b.username,
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            width: 180,
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 170,
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title="Details">
                            <Button
                                className="action-btn-view mr-1"
                                type="primary"
                                shape="squre"
                                icon={<EyeFilled />}
                                onClick={() => {
                                    setPopUpData(record);
                                    setCustomerDetailModal(true);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Button
                                className="action-btn-edit mr-1"
                                onClick={() => {
                                    addCustomerForm.resetFields();
                                    record = { ...record, ...record.customers_extra };
                                    delete record.customers_extra;
                                    setShowExtra(record.is_editable);
                                    addCustomerForm.setFieldsValue(record);
                                    customer.addCustomerModal(true, true);
                                }}
                                type="text"
                                shape="squre"
                                icon={<EditFilled />}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm className="my-pop-confirm" title="Are you sure to delete this User?" onConfirm={() => deleteCustomer(record.id)} okText="Yes" cancelText="No">
                                <Button icon={<DeleteFilled />} className="action-btn-delete mr-1" type="primary" danger shape="squre" />
                            </Popconfirm>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const handleFinish = async (values) => {
        let checkEmpty = false;
        let { role } = getItem("sm-user-info");
        values = { ...values, role: role === "admin" ? "customer" : "customer_user" };

        let finalUpload = { customers_extra: {} };
        for (const key in values) {
            if (["K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(key)) {
                finalUpload.customers_extra[key] = values[key];
                if (values[key]) {
                    checkEmpty = true;
                }
            } else {
                finalUpload[key] = values[key];
            }
        }

        if (checkEmpty === false && values.is_editable) {
            message.error("Columns headers cant be empty.");
            return;
        }
        await customer.addCustomer(finalUpload, addCustomerForm);
    };

    const handleUpdate = async (values) => {
        let checkEmpty = false;
        let id = values.id;
        delete values.id;
        delete values.username;

        let finalUpload = { customers_extra: {} };
        for (const key in values) {
            if (["K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(key)) {
                finalUpload.customers_extra[key] = values[key];
                if (values[key]) {
                    checkEmpty = true;
                }
            } else {
                finalUpload[key] = values[key];
            }
        }
        if (checkEmpty === false && values.is_editable) {
            message.error("Columns fields cant be empty.");
            return;
        }
        await customer.editCustomer(id, finalUpload);
    };

    const showHeaders = ({ target: { checked } }) => {
        setShowExtra(checked);
    };

    const onChange = (pagination, _, sort) => {
        if (sort && Object.keys(sort) && Object.keys(sort).length > 0) {
            customer.getAllCustomer({ page: pagination.current, pageSize: pagination.pageSize, sort });
            return;
        }
    };

    const deleteCustomer = async (id) => {
        await customer.deleteCustomer(id, "admin");
    };

    useEffect(() => {
        customer.getAllCustomer({ page: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row gutter={24}>
                <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        data={customer.customerData || []}
                        columns={columns}
                        loading={customer.isFetchingData}
                        border={true}
                        onChange={onChange}
                        pagination={{ total: customer.totalCustomer, current: customer.page }}
                        scroll={{ x: 950 }}
                        title="Customer List"
                        children={
                            <div className="d-flex justify-content-center align-items-center">
                                <Form.Item className="p-0 m-0 mr-2">
                                    <Input
                                        className="p-1"
                                        placeholder="Search Customers"
                                        prefix={<SearchOutlined />}
                                        onKeyUp={async (e) => {
                                            await customer.getAllCustomer({ page: 1, pageSize: 10, search: e.target.value });
                                        }}
                                    />
                                </Form.Item>

                                <Button
                                    className="bg-primary"
                                    loading={customer.isFetchingData}
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="large"
                                    onClick={() => {
                                        addCustomerForm.resetFields();
                                        customer.addCustomerModal(true);
                                    }}
                                >
                                    Add New Customer
                                </Button>
                            </div>
                        }
                    />
                </Col>
            </Row>
            <CustomModal visibility={customer.showCustomerModal} handleCancel={customer.addCustomerModal} className="add-customer-modal" centered title={customer.isEdit ? "Update Customer" : "Add New Customer"}>
                <div className="add-customer-modal">
                    <Form layout="vertical" form={addCustomerForm} onFinish={customer.isEdit ? handleUpdate : handleFinish}>
                        {customer.isEdit ? (
                            <Form.Item label="id" name="id" hidden={true}>
                                <CustomInput />
                            </Form.Item>
                        ) : (
                            <></>
                        )}
                        <Form.Item
                            label="Display Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Name",
                                },
                            ]}
                        >
                            <CustomInput className="w-100 p-1H7 fs-1" inputtype="alphanumericspace" />
                        </Form.Item>
                        <Form.Item
                            label="Company"
                            name="company"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Company",
                                },
                            ]}
                        >
                            <CustomInput className="w-100 p-1H7 fs-1" inputtype="alphanumericspace" />
                        </Form.Item>
                        <Form.Item
                            label="Customer Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Username",
                                },
                            ]}
                        >
                            <CustomInput disabled={customer.isEdit ? true : false} className="w-100 p-1H7 fs-1" inputtype="alphanumeric" />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={
                                customer.isEdit
                                    ? []
                                    : [
                                          {
                                              required: true,
                                              message: "Please enter Password",
                                          },
                                      ]
                            }
                        >
                            <CustomPassword className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    email: true,
                                    message: "Please enter Email address",
                                },
                            ]}
                        >
                            <CustomInput className="w-100 p-1H7 fs-1" />
                        </Form.Item>
                        {/* {customer.isEdit ? (
                            <></>
                        ) : ( */}
                        <Form.Item name="is_editable" className="mb-0" valuePropName="checked">
                            <Checkbox onChange={showHeaders}>Add extra columns for this customer</Checkbox>
                        </Form.Item>
                        {/* )} */}
                        {showExtra ? (
                            <>
                                <Row gutter={24}>
                                    <Row gutter={24} className="p-2">
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column A" name="K">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column B" name="L">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column C" name="M">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column D" name="N">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column E" name="O">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column F" name="P">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column G" name="Q">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column H" name="R">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column I" name="S">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item label="Extra Column J" name="T">
                                                <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Row>
                            </>
                        ) : (
                            <></>
                        )}
                        <Button block htmlType="submit" size="large" loading={customer.isFetchingData} className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                            {customer.isEdit ? "Update" : "Add"}
                        </Button>
                    </Form>
                </div>
            </CustomModal>

            {/* Customer Detail Modal */}
            <CustomModal visibility={customerDetailModal} handleCancel={setCustomerDetailModal} className="detail-customer-modal" centered title="View Customer Detail">
                <table className="detail-table">
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>Customer Id</td>
                            <td>{popUpData.id}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{popUpData.name}</td>
                        </tr>
                        <tr>
                            <td>Company</td>
                            <td>{popUpData.company}</td>
                        </tr>
                        <tr>
                            <td>Customer Name</td>
                            <td>{popUpData.username}</td>
                        </tr>
                        <tr>
                            <td>Created Date</td>
                            <td>{popUpData.createdAt}</td>
                        </tr>
                        <tr>
                            <td>Editable Fields</td>
                            <td>{popUpData.is_editable ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                </table>
            </CustomModal>
        </>
    );
};

export default inject("customer")(observer(Customers));
