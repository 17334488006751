import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

export default function MyFooter() {
    return (
        <Footer
            style={{
                textAlign: "center",
            }}
        >
            ©{new Date().getFullYear()} Created by Team <b>TOPS Infosolutions PVT LTD.</b>
        </Footer>
    );
}
