/* eslint-disable */
import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import CustomerService from "../../services/Customers.service";

class CustomerStore {
    isFetchingData = false;
    showCustomerModal = false;
    isEdit = false;
    customerData = [];
    totalCustomer = 0;
    page = 1;
    pageSize = 10;
    customerDataDD = [];
    customerUsers = [];

    constructor(allStores) {
        makeAutoObservable(this);
        this.allStores = allStores;
    }

    reset = () => {
        this.isFetchingData = false;
        this.customerData = [];
        this.totalCustomer = 0;
        this.showCustomerModal = false;
        this.isEdit = false;
        this.page = 1;
        this.pageSize = 10;
        this.customerDataDD = [];
        this.customerUsers = [];
    };

    getAllCustomer = async ({ page = 1, pageSize = 10, search = null, sort = null }) => {
        try {
            runInAction(() => {
                this.customerData = [];
                this.isFetchingData = true;
            });
            const data = await CustomerService.getAllCustomersV2({ page, pageSize, search, sort });
            if (data.status < 400) {
                if (data.data.result.totalResults > 0) {
                    let addKey = data.data.result.data.map((elm) => {
                        return { key: elm.id, ...elm };
                    });
                    runInAction(() => {
                        this.customerData = addKey || [];
                        this.totalCustomer = data.data.result.totalResults;
                        this.page = data.data.result.page;
                        this.pageSize = data.data.result.limit;
                    });
                }
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getAllCustomerUsers = async () => {
        try {
            runInAction(() => {
                this.customerUsers = [];
                this.isFetchingData = true;
            });
            const data = await CustomerService.getAllCustomerUsersV2();

            if (data.status < 400 && data.data && data.data.result.length > 0) {
                let addKey = data.data.result.map((elm) => {
                    return { key: elm._id, ...elm };
                });
                runInAction(() => {
                    this.customerUsers = addKey || [];
                });
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getAllCustomerOnly = async () => {
        try {
            runInAction(() => {
                this.customerDataDD = [];
                this.isFetchingData = true;
            });
            const data = await CustomerService.getAllCustomers({});
            if (data.status < 400) {
                if (data.data.result.totalResults > 0) {
                    let addKey = data.data.result.data.map((elm) => {
                        return { key: elm.id, id: elm._id, name: elm.name };
                    });
                    runInAction(() => {
                        this.customerDataDD = addKey || [];
                    });
                }
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    addCustomer = async (values, form, fromUser = false, cuser = false) => {
        try {
            this.isFetchingData = true;
            const data = await CustomerService.addCustomerServiceV2(values);

            if (data.status < 400) {
                message.success(data.data.message);
                form.resetFields();
                if (fromUser) {
                    if (cuser) {
                        this.allStores.user.addUserModal(false);
                        this.allStores.user.addCUserModal(false);
                        this.getAllCustomerUsers();
                        return;
                    }
                    this.allStores.user.addUserModal(false);
                    this.allStores.user.addCUserModal(false);
                    this.allStores.user.getAllUsers({ page: 1 });
                } else {
                    this.addCustomerModal(false);
                    this.getAllCustomer({ page: 1 });
                }
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    editCustomer = async (id, values, fromUser = null) => {
        try {
            this.isFetchingData = true;
            const data = await CustomerService.editCustomerServiceV2(id, values);

            if (data.status < 400) {
                message.success(data.data.message);
                if (fromUser === "customer") {
                    this.allStores.user.addUserModal(false);
                    this.allStores.user.getAllUsers({ page: 1 });
                } else if (fromUser === "customer_user") {
                    this.allStores.user.addCUserModal(false);
                    this.getAllCustomerUsers();
                } else {
                    this.addCustomerModal(false);
                    this.getAllCustomer({ page: 1 });
                }
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    deleteCustomer = async (id, role = "") => {
        try {
            this.isFetchingData = true;
            const data = await CustomerService.deleteCustomerServiceV2(id);

            if (data.status < 400) {
                if (role && role === "admin") {
                    await this.getAllCustomer({ page: 1 });
                } else if (role && role === "customer_user") {
                    await this.getAllCustomerUsers();
                } else {
                    message.success("User deleted successfully!");
                    await this.allStores.user.getAllUsers({ page: 1 });
                }
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    addCustomerModal = async (visibility, edit = false) => {
        runInAction(() => {
            this.showCustomerModal = visibility;
            this.isEdit = edit;
        });
    };
}

export default CustomerStore;
