import { ArrowLeftOutlined, ExclamationCircleFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getItem } from "../../../helper/localStorage";
import CustomInput from "../../common/index";
import { CustomModal } from "../../common/modal";
import { CustomTable } from "../../common/tables";

const EditableContext = React.createContext(null);
let userInfo;
try {
    userInfo = getItem("sm-user-info");
} catch (error) {}

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const Products = ({ project }) => {
    try {
        userInfo = getItem("sm-user-info");
    } catch (error) {}
    const [addProjectForm] = Form.useForm();

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const custId = searchParams.get("customer_id");
    const navigate = useNavigate();

    const [dynamicCols, setDynamicCols] = useState([]);
    const [dynamicData, setDynamicData] = useState([]);
    const [coloumForEdit, setColumnForEdit] = useState({});
    const [currentPagination, setCurrentPagination] = useState({});

    const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };

        const actualUpdate = async (record, values) => {
            let tempRecord = { ...record, ...values };
            let finalUpload = { extra: {}, customers_extra: {} };
            for (const key in tempRecord) {
                if (["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(key)) {
                    if (["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(key)) {
                        finalUpload.extra[key] = tempRecord[key];
                    } else {
                        finalUpload.customers_extra[key] = tempRecord[key];
                    }
                } else {
                    finalUpload[key] = tempRecord[key];
                }
            }

            let itemId = finalUpload.id;
            delete finalUpload.id;
            delete finalUpload.is_deleted;
            delete finalUpload.key;
            delete finalUpload.project_id;
            delete finalUpload.extra_col;
            delete finalUpload.customers_extra_col;

            await project.updateProjectItem(finalUpload, itemId);

            if (select.selectedRowKeys && select.selectedRowKeys.length > 0) {
                let updateMany = { ids: select.selectedRowKeys };
                if (["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(Object.keys(values)[0])) {
                    updateMany.extra = values;
                } else {
                    updateMany.customers_extra = values;
                }
                await project.updateMultipleCols(updateMany, id, currentPagination?.pageSize, custId);
                setSelect({ selectedRowKeys: [] });
            }
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });

                if (values.rfid_tag || values.product_serial_number) {
                    Modal.confirm({
                        title: "Are you sure want to update this column?",
                        icon: <ExclamationCircleFilled />,
                        onOk() {
                            actualUpdate(record, values);
                        },
                        onCancel() {
                            handleSave({
                                ...record,
                            });
                        },
                    });
                } else {
                    actualUpdate(record, values);
                }
            } catch (errInfo) {}
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    useEffect(() => {
        project.getProjectById({ id, page: 1, custId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (project.projectColumn && project.projectColumn.length > 0) {
            const columns = project.projectColumn.map((col) => {
                if (!col.editable) {
                    return col;
                }
                return {
                    ...col,
                    onCell: (record) => ({
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave,
                    }),
                };
            });
            setDynamicCols(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.projectColumn]);

    useEffect(() => {
        setDynamicData(() => toJS(project.itemList));
    }, [project.itemList]);

    useEffect(() => {
        if (project.singleProject.extra || project.singleProject.customers_extra) {
            // if (userInfo.role === "admin") {
            //     setColumnForEdit(project.singleProject.extra);
            // } else {
            //     setColumnForEdit(project.singleProject.customers_extra);
            // }
            setColumnForEdit({ ...project.singleProject.extra, ...project.singleProject.customers_extra });
        }
    }, [project.singleProject]);

    const handleUpdate = async (values) => {
        // let finalUpload = userInfo.role === "admin" ? { extra: {} } : { customers_extra: {} };
        // for (const key in values) {
        //     const checkList = userInfo.role === "admin" ? ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"] : ["K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"];
        //     if (checkList.includes(key)) {
        //         if (userInfo.role === "admin") {
        //             finalUpload.extra[key] = values[key];
        //         } else {
        //             finalUpload.customers_extra[key] = values[key];
        //         }
        //     }
        // }

        let finalUpload = { extra: {} };
        for (const key in values) {
            // const checkList = userInfo.role === "admin" ? ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"] : ["K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"];
            if (["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(key)) {
                finalUpload.extra[key] = values[key];
            }
        }
        await project.updateColumns(finalUpload, id, custId);
    };

    const onChange = (pagination, _, sort) => {
        setCurrentPagination(pagination);
        if (sort && Object.keys(sort) && Object.keys(sort).length > 0) {
            project.getProjectById({ id, page: pagination.current, pageSize: pagination.pageSize, custId, sort });
            return;
        }
        project.getProjectById({ id, page: pagination.current, pageSize: pagination.pageSize, custId });
    };

    const handleSave = (row) => {
        setDynamicData((prevLst) => {
            const newData = [...prevLst];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            return newData;
        });
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const [select, setSelect] = useState({
        selectedRowKeys: [],
    });

    const { selectedRowKeys } = select;

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
            });
        },
    };

    return (
        <>
            <Row gutter={24}>
                <Col className="mt-2" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <CustomTable
                        data={dynamicData || []}
                        columns={dynamicCols}
                        components={components}
                        rowSelection={userInfo.role === "sm_user" ? null : rowSelection}
                        border={true}
                        rowClassName={() => "editable-row"}
                        onChange={onChange}
                        loading={project.isFetchingData}
                        pagination={{ total: project.totalProjectsItems, current: project.totalItemsPage > 0 ? project.totalItemsPage : 1, pageSizeOptions: [10, 20, 50, 100, project.totalProjectsItems] }}
                        scroll={{ x: true }}
                        rowKey={({ key }) => key}
                        title={
                            <span className="archive-arrow">
                                <ArrowLeftOutlined onClick={() => navigate("/projects")} />
                                <span className="p-0 m-0">Project List</span>
                            </span>
                        }
                        children={
                            userInfo.role === "admin" ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <Form.Item className="p-0 m-0 mr-2">
                                        <Input
                                            className="p-1"
                                            placeholder="Search Products"
                                            prefix={<SearchOutlined />}
                                            onKeyUp={async (e) => {
                                                await project.getProjectById({ id, page: 1, custId, search: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                    <Button
                                        className="bg-primary"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        size="large"
                                        onClick={async () => {
                                            project.manageColumnModal(true);
                                        }}
                                    >
                                        Manage Columns
                                    </Button>
                                </div>
                            ) : (
                                <Form.Item className="p-0 m-0">
                                    <Input
                                        className="p-1"
                                        placeholder="Search Products"
                                        prefix={<SearchOutlined />}
                                        onKeyUp={async (e) => {
                                            await project.getProjectById({ id, page: 1, custId, search: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                            )
                        }
                    />
                </Col>
            </Row>
            <CustomModal visibility={project.showManageColumnModal} handleCancel={project.manageColumnModal} className="add-project-modal" centered title="Manage Columns">
                <div className="manage-columns-modal">
                    <Form layout="vertical" initialValues={coloumForEdit} form={addProjectForm} onFinish={handleUpdate}>
                        <Row gutter={24}>
                            <Row gutter={24} className="p-2">
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Column A"
                                        name={userInfo.role === "admin" ? "A" : "K"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column A",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Column B"
                                        name={userInfo.role === "admin" ? "B" : "L"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column A",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Column C"
                                        name={userInfo.role === "admin" ? "C" : "M"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column C",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Column D"
                                        name={userInfo.role === "admin" ? "D" : "N"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column D",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Extra Column E"
                                        name={userInfo.role === "admin" ? "E" : "O"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column E",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Extra Column F"
                                        name={userInfo.role === "admin" ? "F" : "P"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column F",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Extra Column G"
                                        name={userInfo.role === "admin" ? "G" : "Q"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column G",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Extra Column H"
                                        name={userInfo.role === "admin" ? "H" : "R"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column H",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Extra Column I"
                                        name={userInfo.role === "admin" ? "I" : "S"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column I",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item
                                        label="Extra Column J"
                                        name={userInfo.role === "admin" ? "J" : "T"}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Please enter Column J",
                                        //     },
                                        // ]}
                                    >
                                        <CustomInput inputtype="alphanumericspace" className="w-100 p-1H7 fs-1" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Row>
                        <Button block htmlType="submit" loading={project.isFetchingData} size="large" className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                            Update
                        </Button>
                    </Form>
                </div>
            </CustomModal>
        </>
    );
};

export default inject("project")(observer(Products));
