import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";

export const CustomModal = ({ visibility, handleCancel, centered, ...props }) => {
    return (
        <>
            <Modal
                className="customer-modal"
                open={visibility}
                footer={null}
                centered={centered}
                maskClosable={props.closable}
            >
                <div className="view-customer-details">
                    <div className="modal-header">
                        <span></span>
                        <p className="header-title">{props.title}</p>
                        <div><CloseOutlined className="close-btn" onClick={() => handleCancel(false)} /></div>
                    </div>
                    <div className="modal-body">{props.children}</div>
                </div>
            </Modal>
        </>
    );
};
