/* eslint-disable no-unused-vars */

import { EnterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Grid, List, Radio, Checkbox, Row, Select } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../common/index";

// import { useNavigate } from "react-router-dom";
// import { getItem } from "../../../helper/localStorage";
import { CustomTable } from "../../common/tables";
const { useBreakpoint } = Grid;
// import { IconCustomers, IconProjects } from "../../sidebar/icons/icon";

const Dashboard = ({ dashboard, auth }) => {
    // const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [productList, setProductList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [timeframe, setTimeframe] = useState(1);
    const [marketplaceFilter, setMarketplaceFilter] = useState("");
    const [fChannelFilter, setFChannelFilter] = useState();
    const [sellerSkuFilter, setSellerSkuFilter] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [tblResult, setTblResult] = useState([]);
    const [filterForm] = Form.useForm();

    const screens = useBreakpoint();

    function getISOWeekNumberFromDateStr(dateStr) {
        if (dateStr) {
            const [day, month, year] = dateStr.split("-").map(Number);
            const parsedDate = new Date(year, month - 1, day);
            parsedDate.setDate(parsedDate.getDate() + 4 - (parsedDate.getDay() || 7));
            const targetYear = parsedDate.getFullYear();
            const firstDayOfYear = new Date(targetYear, 0, 1);
            const diffDays = Math.floor((parsedDate - firstDayOfYear) / (24 * 60 * 60 * 1000));
            const weekNumber = Math.ceil((diffDays + 1) / 7);
            return weekNumber;
        }
        return dateStr;
    }

    const projectColumns = [
        {
            title: "Alerts",
            key: "Alerts",
            dataIndex: "fulfillment_channel_title",
            align: "center",
            render: (_, item) => {
                if (!item.root) {
                    return (
                        <>
                            Week {getISOWeekNumberFromDateStr(item.week_start)} ({item.week_start})
                        </>
                    );
                }
                return (
                    <>
                        {item.fulfillment_channel_title}/{item.sales_channel}
                    </>
                );
            },
        },
        {
            title: "Weekly Average",
            align: "center",
            dataIndex: "tblData",
            children: [
                {
                    title: "Sales Rate",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "quantity_sum",
                },
                {
                    title: "Delta Sales Rate",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "quantity_change_from_last_week",
                },
                {
                    title: "Sales Amount",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "revenue_sum",
                },
                {
                    title: "Delta Sales Amount (%)",
                    align: "center",
                    style: "bg-primary",
                    dataIndex: "revenue_change_percentage_from_last_week",
                },
            ],
        },
    ];

    const beautifyNumber = (number, round = true) => {
        return Intl.NumberFormat("en-US").format(round ? Math.round(number) : number);
    };

    const channelSKUFilter = (value) => {
        setFilter(value);
    };

    const changeFChannel = (value) => {
        setFChannelFilter(value);
    };

    const changeTimeframe = (e) => {
        setTimeframe(e.target.value);
        const frmValue = filterForm.getFieldsValue();
        if (frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertChannelSKUs(false, frmValue.revenue_threshold, frmValue.sales_rate_threshold, e.target.value, fChannelFilter, marketplaceFilter);
        }
        if (selectedItem && frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertInfoDetailsByChannelSKU(selectedItem, frmValue.revenue_threshold, frmValue.sales_rate_threshold, sellerSkuFilter, e.target.value, fChannelFilter, marketplaceFilter);
        }
    };

    const refreshButtonClick = () => {
        const frmValue = filterForm.getFieldsValue();
        if (frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertChannelSKUs(false, frmValue.revenue_threshold, frmValue.sales_rate_threshold, timeframe, fChannelFilter, marketplaceFilter);
        }
        if (selectedItem && frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertInfoDetailsByChannelSKU(selectedItem, frmValue.revenue_threshold, frmValue.sales_rate_threshold, sellerSkuFilter, timeframe, fChannelFilter, marketplaceFilter);
        }
    };

    const convertJSONToData = (json) => {
        const transformedData = [];

        json.forEach((item) => {
            let obj = {
                key: `${item.fulfillment_channel_title}${item.sales_channel}${item.week_start}`.replaceAll("-", ""),
                fulfillment_channel_title: item.fulfillment_channel_title,
                sales_channel: item.sales_channel,
                quantity_sum: item.quantity_sum ? <b>{beautifyNumber(item.quantity_sum, true)}</b> : "-",
                quantity_change_from_last_week: <b>{item.quantity_change_from_last_week}</b>,
                revenue_sum: item.revenue_sum ? (
                    <b>
                        {beautifyNumber(item.revenue_sum, true)} {item.currency_code}
                    </b>
                ) : (
                    "-"
                ),
                revenue_change_percentage_from_last_week: <b>{item.revenue_change_percentage_from_last_week}</b>,
                root: true,
                children: [],
            };
            if (item.children.previous_week.week_start) {
                obj.children.push({
                    fulfillment_channel_title: "",
                    sales_channel: "",
                    week_start: item.children.previous_week?.week_start,
                    quantity_sum: item.children.previous_week?.quantity_sum ? beautifyNumber(item.children.previous_week?.quantity_sum, true) : "-",
                    quantity_change_from_last_week: item.children.previous_week?.quantity_change_from_last_week,
                    revenue_sum: item.children.previous_week?.revenue_sum ? `${beautifyNumber(item.children.previous_week?.revenue_sum, true)} ${item.children.previous_week.currency_code}` : "-",
                    revenue_change_percentage_from_last_week: item.children.previous_week?.revenue_change_percentage_from_last_week,
                });
            }
            obj.children.push({
                fulfillment_channel_title: "",
                sales_channel: "",
                week_start: item.week_start,
                quantity_sum: item.quantity_sum ? <b>{beautifyNumber(item.quantity_sum, true)}</b> : "-",
                quantity_change_from_last_week: <b>{item.quantity_change_from_last_week}</b>,
                revenue_sum: item.revenue_sum ? (
                    <b>
                        {beautifyNumber(item.revenue_sum, true)} {item.currency_code}
                    </b>
                ) : (
                    "-"
                ),
                revenue_change_percentage_from_last_week: <b>{item.revenue_change_percentage_from_last_week}</b>,
            });
            if (item.children.next_week.week_start) {
                obj.children.push({
                    fulfillment_channel_title: "",
                    sales_channel: "",
                    week_start: item.children.next_week?.week_start,
                    quantity_sum: item.children.next_week?.quantity_sum ? beautifyNumber(item.children.next_week?.quantity_sum, true) : "-",
                    quantity_change_from_last_week: item.children.next_week?.quantity_change_from_last_week,
                    revenue_sum: item.children.next_week?.revenue_sum ? `${beautifyNumber(item.children.next_week?.revenue_sum, true)} ${item.children.next_week.currency_code}` : "-",
                    revenue_change_percentage_from_last_week: item.children.next_week?.revenue_change_percentage_from_last_week,
                });
            }
            transformedData.push(obj);
        }, []);

        return transformedData;
    };

    const handleItemClick = (channel_sku) => {
        filterForm.setFieldsValue({ sellersku: "ALL" });
        setSellerSkuFilter("");
        setSelectedItem(channel_sku);
        const frmValue = filterForm.getFieldsValue();
        dashboard.getSalesAlertInfoDetailsByChannelSKU(channel_sku, frmValue.revenue_threshold, frmValue.sales_rate_threshold, sellerSkuFilter, timeframe, fChannelFilter, marketplaceFilter);
    };

    const renderItem = (item, index) => {
        const isSelected = selectedItem === item;
        return (
            <List.Item key={`ITEM-${index}`} onClick={() => handleItemClick(item)} className={isSelected ? "bg-primary text-white" : ""}>
                {item}
            </List.Item>
        );
    };

    const changeMarketplace = (val) => {
        setMarketplaceFilter(val ? val.join() : "");
        const frmValue = filterForm.getFieldsValue();
        if (frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertChannelSKUs(false, frmValue.revenue_threshold, frmValue.sales_rate_threshold, timeframe, fChannelFilter, val.join());
        }
        if (selectedItem && frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertInfoDetailsByChannelSKU(selectedItem, frmValue.revenue_threshold, frmValue.sales_rate_threshold, sellerSkuFilter, timeframe, fChannelFilter, val.join());
        }
    };

    const changeSellerSKU = (val) => {
        setSellerSkuFilter(val ? val : "");
        const frmValue = filterForm.getFieldsValue();
        if (selectedItem && frmValue.revenue_threshold && frmValue.sales_rate_threshold) {
            dashboard.getSalesAlertInfoDetailsByChannelSKU(selectedItem, frmValue.revenue_threshold, frmValue.sales_rate_threshold, val ? val : "", timeframe, fChannelFilter, marketplaceFilter);
        }
    };

    useEffect(() => {
        dashboard.getSalesAlertChannelSKUs(true, 0, -20);
        dashboard.getMarketplaces(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dashboard.salesAlertSKUList?.result) {
            setProductList(dashboard.salesAlertSKUList?.result);
            setFilteredList(dashboard.salesAlertSKUList?.result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard.salesAlertSKUList?.result]);

    useEffect(() => {
        setFilteredList(productList.filter((item) => item.toLowerCase().includes(filter.toLowerCase())));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        setFChannelFilter(() => {
            let tmpLst = [];
            if (auth.user.is_fba) {
                tmpLst.push("FBA");
            }
            if (auth.user.is_mfn) {
                tmpLst.push("MFN");
            }
            setFChannelFilter(tmpLst);
            return tmpLst;
        });
    }, [auth.user]);

    useEffect(() => {
        setTblResult(dashboard.salesAlertChannelSKUDetail.result);
    }, [dashboard.salesAlertChannelSKUDetail.result]);

    return (
        <>
            <Row gutter={24}>
                <Col id="ChannelSKUId" span={3}>
                    <>
                        <CustomInput onChange={channelSKUFilter} placeholder="Search Here" className="mb-2 p-1H7 fs-11" />
                        <List bordered loading={dashboard.isFetchingData} style={{ maxHeight: "100vh", overflowY: "scroll", background: "white" }} dataSource={filteredList} renderItem={renderItem}></List>
                    </>
                </Col>
                <Col span={21}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Radio.Group optionType="button" onChange={changeTimeframe} className="w-100" buttonStyle="solid" size="small" defaultValue="1">
                                <Radio.Button value="1">1 Month</Radio.Button>
                                <Radio.Button value="2">2 Months</Radio.Button>
                                <Radio.Button value="3">3 Months</Radio.Button>
                                <Radio.Button value="6">6 Months</Radio.Button>
                                <Radio.Button value="9">9 Months</Radio.Button>
                                <Radio.Button value="12">12 Months</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Form
                            colon={false}
                            form={filterForm}
                            initialValues={{ sellersku: undefined, revenue_threshold: auth.user.revenue_change, sales_rate_threshold: auth.user.units_sales_change, inventory_threshold: auth.user.min_inventory }}
                            layout="inline"
                            className="w-100"
                        >
                            <Col className="mt-2 remove-right-gutter" xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                                <Form.Item className="p-0 m-0" name="marketplaces">
                                    <Select onChange={changeMarketplace} className="w-100 my-custom-multiselect" options={dashboard.productInfoMPList} mode="multiple" maxTagCount="responsive" allowClear placeholder="Select Marketplaces" />
                                </Form.Item>
                            </Col>
                            <Col className="mt-2 remove-right-gutter" xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                                <Form.Item className="p-0 m-0" name="sellersku">
                                    <Select onChange={changeSellerSKU} allowClear className="w-100" placeholder="Select Seller SKU" options={dashboard.salesAlertChannelSKUDetail.all_sku} />
                                </Form.Item>
                            </Col>
                            <Col className="mt-2 my-custom-btn" xs={24} sm={24} md={24} lg={2} xl={2} xxl={2}>
                                <Button block htmlType="submit" size="large" className="w-100 bg-primary text-white btn-custom-height-1" onClick={refreshButtonClick}>
                                    {screens.xs === false && screens.xxl === false ? <ReloadOutlined title="Refresh" /> : "Refresh"}
                                </Button>
                            </Col>
                            <Col className="mt-2 remove-right-gutter ant-form-lbl-1" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                <Form.Item label="Revenue Change" className="p-0 m-0" name="revenue_threshold">
                                    <CustomInput className="p-1H7 fs-1" />
                                </Form.Item>
                            </Col>
                            <Col className="mt-2 remove-right-gutter ant-form-lbl-1" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                <Form.Item label="Unit Sales Change" className="p-0 m-0" name="sales_rate_threshold">
                                    <CustomInput className="p-1H7 fs-1" />
                                </Form.Item>
                            </Col>
                            <Col className="mt-2 ant-form-lbl-1 remove-right-gutter" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                <Form.Item label="Min Inventory" className="p-0 m-0" name="inventory_threshold">
                                    <CustomInput className="p-1H7 fs-1" />
                                </Form.Item>
                            </Col>
                            <Col className="d-flex align-items-center pt-2" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                <Checkbox.Group value={fChannelFilter} options={["FBA", "MFN"]} style={{ height: "100%" }} onChange={changeFChannel} className="w-100 my-border d-flex justify-content-evenly align-items-center" size="large" />
                            </Col>
                        </Form>
                    </Row>
                    <Row className="mt-1" gutter={24}>
                        <Col className="mt-1 my-custom-table custom-table-cell-left" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <CustomTable
                                style={{ minHeight: 500, maxHeight: 800, overflowY: "scroll" }}
                                loading={dashboard.isFetchingData}
                                data={convertJSONToData(dashboard.salesAlertChannelSKUDetail?.result || [])}
                                columns={projectColumns}
                                pagination={false}
                                border={true}
                                size="small"
                                scroll={{ x: "100%" }}
                                title={dashboard.salesAlertChannelSKUDetail.description ? <p className="w-100 p-2 m-0 bg-secondary">{dashboard.salesAlertChannelSKUDetail.description}</p> : ""}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default inject("dashboard", "auth")(observer(Dashboard));
