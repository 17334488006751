/* eslint-disable */
import { message } from "antd";
import { makeAutoObservable } from "mobx";
import DashboardService from "../../services/Dashboard.service";

class DashboardStore {
    isFetchingData = false;
    dashboardData = [];
    channelSalesData = [];
    productInfoSKUList = [];
    productInfoMPList = [];
    productInfoChannelSKUDetail = {};
    salesAlertChannelSKUDetail = [];
    salesAlertSKUList = [];

    constructor(allStores) {
        makeAutoObservable(this);
        this.allStores = allStores;
    }

    reset = () => {
        this.isFetchingData = false;
        this.dashboardData = [];
        this.channelSalesData = [];
        this.productInfoSKUList = [];
        this.productInfoMPList = [];
        this.productInfoChannelSKUDetail = {};
        this.salesAlertChannelSKUDetail = [];
        this.salesAlertSKUList = [];
    };

    getDashboard = async (timeframe = "today") => {
        try {
            this.isFetchingData = true;
            const data = await DashboardService.fetchDashboardService(timeframe);

            if (data.status < 400) {
                this.dashboardData = data.data.data;
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getChannelSales = async (timeframe = "today") => {
        try {
            this.isFetchingData = true;
            const data = await DashboardService.fetchChannelSalesService(timeframe);

            if (data.status < 400) {
                this.channelSalesData = data.data.data;
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getProductInfoChannelSKUs = async () => {
        try {
            this.isFetchingData = true;
            const data = await DashboardService.fetchChannelSKUListService();

            if (data.status < 400) {
                this.productInfoSKUList = data.data.data;
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getProductInfoDetailsByChannelSKU = async (channel_sku, timeframe = "today", marketplace = "", sellersku = "") => {
        try {
            this.isFetchingData = true;
            const data = await DashboardService.fetchChannelSKUDetailsService(channel_sku, timeframe, marketplace, sellersku);

            if (data.status < 400) {
                this.productInfoChannelSKUDetail = data.data.data?.result;
                if (data.data.data?.result?.all_sku) {
                    this.productInfoChannelSKUDetail.all_sku = data.data.data?.result?.all_sku.map((itm) => {
                        return { title: itm, value: itm };
                    });
                    this.productInfoChannelSKUDetail.all_sku.unshift({ title: "ALL", value: "ALL" });
                }
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getMarketplaces = async (myMP = false) => {
        try {
            this.isFetchingData = true;
            const data = await DashboardService.fetchMarketplaceService(myMP);

            if (data.status < 400) {
                this.productInfoMPList = data.data.data.result.map((item) => {
                    return { label: item.countryName, value: item.countryCode };
                });
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getSalesAlertChannelSKUs = async (init = false, revenue_threshold, sales_rate_threshold, timeframe = 1, f_channel = undefined, m_places = undefined) => {
        try {
            this.isFetchingData = true;

            if (init) {
                revenue_threshold = this.allStores.auth.user.revenue_change;
                sales_rate_threshold = this.allStores.auth.user.units_sales_change;
            }else{
                revenue_threshold = revenue_threshold ? revenue_threshold : this.allStores.auth.user.revenue_change;
                sales_rate_threshold = sales_rate_threshold ? sales_rate_threshold : this.allStores.auth.user.units_sales_change;
            }

            const data = await DashboardService.fetchChannelSKUListSAService(revenue_threshold, sales_rate_threshold, timeframe, f_channel, m_places);

            if (data.status < 400) {
                this.salesAlertSKUList = data.data.data;
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getSalesAlertInfoDetailsByChannelSKU = async (channel_sku, revenue_threshold, sales_rate_threshold, sellersku = "", timeframe = 1, f_channel = undefined, m_places = undefined) => {
        try {
            this.isFetchingData = true;
            const data = await DashboardService.fetchSAChannelSKUDetailsService(channel_sku, revenue_threshold, sales_rate_threshold, sellersku, timeframe, f_channel, m_places);

            if (data.status < 400) {
                this.salesAlertChannelSKUDetail = data.data.data || [];
                if (data.data.data?.all_sku) {
                    this.salesAlertChannelSKUDetail.all_sku = data.data.data?.all_sku.map((itm) => {
                        return { title: itm, value: itm };
                    });
                    this.salesAlertChannelSKUDetail.all_sku.unshift({ title: "ALL", value: "ALL" });
                }
                this.isFetchingData = false;
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };
}

export default DashboardStore;
