import React from "react";

export const Cosmatics = (props) => {
    return (
        <div className="dashboard-cosmatic">
            {/* <div className="top-right-icon">{props.icon}</div> */}
            <div className="left-part">
                <div className="cosmatic-icon">{props.icon}</div>
            </div>
            <div className="right-part">
                {props.counts}
                <p className="cosmatic-title">{props.title}</p>
            </div>
        </div>
    );
};
