import { encryptValue, decryptValue } from "./helper";

const { localStorage } = window;

// get item from localStorage
export const getItem = (name, decrypt = true) => {
    const encryptedValue = localStorage.getItem(name);
    let valueToBeReturned = encryptedValue;

    if (encryptedValue) {
        if (decrypt) {
            // decrypt encrypted value
            valueToBeReturned = decryptValue(encryptedValue);
            try {
                valueToBeReturned = JSON.parse(valueToBeReturned);
            } catch (error) {}
        }
        // use JSON.parse for object/array
        if (["user"].includes(name)) {
            return JSON.parse(valueToBeReturned);
        }
        return valueToBeReturned;
    }

    // use JSON.parse for object/array
    if (["user"].includes(name)) {
        return JSON.parse(valueToBeReturned);
    }

    return valueToBeReturned;
};

// set item in localStorage
export const setItem = (name, value) => {
    let valueString;

    if (["array", "object"].includes(typeof value)) {
        valueString = JSON.stringify(value);
    } else {
        valueString = String(value);
    }

    let encryptedValue = valueString;

    if (value) {
        // encrypt value
        encryptedValue = encryptValue(valueString);
    }
    localStorage.setItem(name, encryptedValue);
};

// remove item from localStorage
export const removeItem = (name) => {
    localStorage.removeItem(name);
};
