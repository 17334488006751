/* eslint-disable */
import { message, Popconfirm, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { makeAutoObservable, runInAction } from "mobx";
import ProjectsService from "../../services/Projects.service";
import { getItem } from "../../helper/localStorage";

class ProjectsStore {
    isFetchingData = false;
    showProjectModal = false;
    showManageColumnModal = false;
    projectData = [];
    totalProjects = 0;
    totalProjectsItems = 0;
    totalItemsPage = 0;
    page = 1;
    archiveProjectData = [];
    totalArchiveProjects = 0;
    pageArchive = 1;
    singleProject = {};
    projectColumn = {};
    itemList = [];
    isEditPROJECT = false;

    constructor(allStores) {
        makeAutoObservable(this);
        this.allStores = allStores;
    }

    reset = () => {
        this.isFetchingData = false;
        this.showProjectModal = false;
        this.showManageColumnModal = false;
        this.projectData = [];
        this.totalProjects = 0;
        this.totalProjectsItems = 0;
        this.totalItemsPage = 0;
        this.page = 1;
        this.archiveProjectData = [];
        this.totalArchiveProjects = 0;
        this.pageArchive = 1;
        this.singleProject = {};
        this.projectColumn = {};
        this.itemList = [];
        this.isEditPROJECT = false;
    };

    getAllProjects = async () => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.fetchProjectsService();

            if (data.status < 400) {
                let addKey = data.data.result.data.map((elm) => {
                    return { ...elm, key: `${elm.key}-${Math.round(Math.random() * 100)}` };
                });
                this.projectData = addKey;
                this.isFetchingData = false;
            } else {
                this.isFetchingData = false;
                message.error(data.response.data.message || "Something went Wrong.!");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getAllArchiveProjects = async ({ page = 1 }) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.fetchArchiveProjectsService({ page });

            if (data.status < 400) {
                // if (data.data.result.totalResults > 0) {
                let addKey = data.data.result.data.map((elm) => {
                    return { key: elm.id, ...elm };
                });
                this.archiveProjectData = addKey;
                this.totalArchiveProjects = data.data.result.totalResults;
                this.pageArchive = data.data.result.page;
                // }
                this.isFetchingData = false;
            } else {
                this.isFetchingData = false;
                message.error(data.response.data.message || "Something went Wrong.!");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    addProject = async (values, form) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.addProjectsService(values);

            if (data.status < 400) {
                message.success(data.data.message);
                form.resetFields();
                this.addProjectModal(false);
                this.getAllProjects({ page: 1 });
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    getProjectById = async ({ id, page, pageSize, custId = null, search = null, sort = null }) => {
        try {
            runInAction(() => {
                this.singleProject = {};
                this.itemList = [];
                this.projectColumn = [];
            });
            const { role, is_editable } = getItem("sm-user-info");
            this.isFetchingData = true;
            const data = await ProjectsService.getProjectById({ id, custId });
            const itemData = await ProjectsService.getProductsByProjectId({ id, page, pageSize, custId, search, sort });

            if (data.status < 400) {
                runInAction(() => {
                    this.singleProject = data.data.result;
                    this.itemList = itemData.data.result.data;
                    this.totalProjectsItems = itemData.data.result.totalResults;
                    this.totalItemsPage = itemData.data.result.page;
                });

                let tempCol = [
                    {
                        title: "RFID TagID",
                        dataIndex: "rfid_tag",
                        width: 150,
                        render: (val, _, record) => (val === "" || val === undefined ? "-" : val),
                        // editable: role === "admin" ? true : false,
                        sorter: (a, b) => a.rfid_tag - b.rfid_tag,
                    },
                    {
                        title: "Serial Number",
                        dataIndex: "product_serial_number",
                        width: 150,
                        render: (val, _, record) => (val === "" || val === undefined ? "-" : val),
                        editable: role === "admin" ? true : false,
                        sorter: (a, b) => a.product_serial_number - b.product_serial_number,
                    },
                ];

                if (["admin", "customer", "customer_user"].includes(role)) {
                    if (data.data.result.extra && Object.keys(data.data.result.extra).length > 0) {
                        Object.keys(data.data.result.extra).forEach((element) => {
                            let elm = data.data.result.extra[element];
                            if (elm != "") {
                                tempCol.push({
                                    title: elm,
                                    dataIndex: element,
                                    width: 150,
                                    render: (val, _, record) => (val === "" || val === undefined ? "-" : val),
                                    editable: role === "admin" ? true : false,
                                    sorter: (a, b) => a.element - b.element,
                                });
                            }
                        });
                    }
                }

                if (["admin", "customer", "customer_user"].includes(role)) {
                    if (data.data.result.customers_extra && Object.keys(data.data.result.customers_extra).length > 0) {
                        Object.keys(data.data.result.customers_extra).forEach((element) => {
                            let elm = data.data.result.customers_extra[element];
                            if (elm !== "") {
                                tempCol.push({
                                    title: elm,
                                    dataIndex: element,
                                    width: 150,
                                    render: (val, _, record) => (val === "" || val === undefined ? "-" : val),
                                    editable: role === "admin" ? true : is_editable == true ? true : false,
                                    sorter: (a, b) => a.element - b.element,
                                });
                            }
                        });
                    }
                }

                this.projectColumn = tempCol;
            }
            this.isFetchingData = false;
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    deleteProject = async (id) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.deleteProjectsService(id);

            if (data.status < 400) {
                message.success(data.data.message);
                this.getAllProjects({ page: 1 });
            } else {
                this.isFetchingData = false;
                message.error(data.response.data.message || "Something went Wrong.!");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    updateColumns = async (values, id, custId = null) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.updateColumnService(values, id);

            if (data.status < 400) {
                message.success(data.data.message);
                if (custId === null) {
                    this.addProjectModal(false);
                    this.getAllProjects({ page: 1 });
                } else {
                    this.manageColumnModal(false);
                    this.getProjectById({ id, page: 1, custId });
                }
            }

            this.isFetchingData = false;
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    updateProjectItem = async (values, id) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.updateProjectItemService(values, id);

            if (data.status < 400) {
                message.success(data.data.message);
            }
            this.isFetchingData = false;
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    archiveProject = async (id) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.archiveProjectsService(id);

            if (data.status < 400) {
                message.success(data.data.message);
                this.getAllProjects({ page: 1 });
            } else {
                this.isFetchingData = false;
                message.error(data.response.data.message || "Something went Wrong.!");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    updateMultipleCols = async (vals, id, pageSize, custId = null) => {
        try {
            this.isFetchingData = true;
            const data = await ProjectsService.updateManyColumns(vals);

            if (data.status < 400) {
                this.getProjectById({ id, page: 1, pageSize, custId });
            } else {
                this.isFetchingData = false;
                message.error(data.response.data.message || "Something went Wrong.!");
            }
        } catch (error) {
            message.error(error.response.data.message || error || "Something went Wrong.!");
            this.isFetchingData = false;
        }
    };

    addProjectModal = async (visibility, isEdit = false) => {
        runInAction(() => {
            this.showProjectModal = visibility;
            this.isEditPROJECT = isEdit;
        });
    };

    manageColumnModal = async (visibility) => {
        runInAction(() => {
            this.showManageColumnModal = visibility;
        });
    };
}

export default ProjectsStore;
