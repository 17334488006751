// import axios from "axios";
import axios from "./Provider/AuthProvider";

class DashboardService {
    fetchDashboardService = async (timeframe) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/sales-summary?timeframe=${timeframe}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchChannelSalesService = async (timeframe) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/channel-summary?timeframe=${timeframe}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchChannelSKUListService = async () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/product-channels`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchMarketplaceService = async (myMP = false) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/fetch-marketplaces${myMP ? `?self=1` : "?self=0"}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchChannelSKUDetailsService = async (channel_sku, timeframe, marketplace, sellersku) => {
        let urlParams = `?timeframe=${timeframe}`;

        if (marketplace !== "") {
            urlParams += `&marketplaces=${marketplace}`;
        }

        if (sellersku !== "" && sellersku !== "ALL") {
            urlParams += `&sellersku=${sellersku}`;
        }

        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/product-channels/${channel_sku}${urlParams}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchChannelSKUListSAService = async (revenue_threshold, sales_rate_threshold, timeframe, f_channel, m_places) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/sales-alert-channel-sku/${revenue_threshold}/${sales_rate_threshold}`;

        if (timeframe || f_channel || m_places) {
            url += "?";
            if (timeframe) {
                url += `timeframe=${timeframe}`;
            }

            if (f_channel) {
                url += `&fulfillment_channels=${f_channel}`;
            }

            if (m_places) {
                url += `&marketplaces=${m_places}`;
            }
        }

        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchSAChannelSKUDetailsService = async (channel_sku, revenue_threshold, sales_rate_threshold, sellersku, timeframe, f_channel, m_places) => {
        let urlParams = `?timeframe=${timeframe}`;

        if (m_places !== "" && m_places !== undefined) {
            urlParams += `&marketplaces=${m_places}`;
        }

        if (f_channel !== "" && f_channel !== undefined) {
            urlParams += `&fulfillment_channels=${f_channel.join(",")}`;
        }

        if (sellersku !== "" && sellersku !== "ALL") {
            urlParams += `&sellersku=${sellersku}`;
        }

        let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/sales-alert/${channel_sku}/${revenue_threshold}/${sales_rate_threshold}${urlParams}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default new DashboardService();
