import axios from "axios";

class AuthService {
    loginService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/login`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    resetPasswordService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/update-profile`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    logoutService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/auth/logout`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    fetchMyInfoService = async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/me`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    fetchMerchantDetailsService = async (masked = false) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/get_merchant_info?${masked ? "masked=1" : "masked=0"}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    fetchAllCurrencyService = async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/fetch-all-currency`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    fetchAllTimezoneService = async () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/account/fetch-timezones`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    forgotPasswordService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/auth/forgot-password`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    setPasswordService = async (data, token) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/auth/reset-password-byemail?token=${token}`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    updateProfileService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/account/update-profile`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default new AuthService();
