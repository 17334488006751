import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import MainComp from "./components";
import ArchiveProject from "./components/pages/archive-project";
import ForgotPassword from "./components/pages/auth/forgotPassword";
import SetNewPassword from "./components/pages/auth/resetNewPassword";
import Login from "./components/pages/auth/login";
import Customers from "./components/pages/customers";
import SalesDashboard from "./components/pages/sales-dashboard";
import ChannelSales from "./components/pages/channel-sales";
import ProductInfo from "./components/pages/product-info";
import SalesAlert from "./components/pages/sales-alert";
import Users from "./components/pages/manage-users";
import Projects from "./components/pages/projects";
import Products from "./components/pages/projects/Products";
import NormalRoute from "./NormalRoute";
import { ProtectRoute } from "./Protected";

function App({ routes }) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        routes.setRoute(location, params, navigate);
    });

    return (
        <Routes>
            <Route path="*" element={<h1>Page Not Found</h1>} />
            <Route
                path="/"
                element={
                    <NormalRoute>
                        <Login />
                    </NormalRoute>
                }
            />
            <Route
                path="/forgot-password"
                element={
                    <NormalRoute>
                        <ForgotPassword />
                    </NormalRoute>
                }
            />
            <Route
                path="/set-password/:token"
                element={
                    <NormalRoute>
                        <SetNewPassword />
                    </NormalRoute>
                }
            />

            <Route
                path="/dashboard"
                element={
                    <ProtectRoute role={["systemAdmin", "merchantAdmin", "customer", "customer_user"]}>
                        <MainComp comp={<SalesDashboard />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/channel-sales"
                element={
                    <ProtectRoute role={["systemAdmin", "merchantAdmin", "customer", "customer_user"]}>
                        <MainComp comp={<ChannelSales />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/product-info"
                element={
                    <ProtectRoute role={["systemAdmin", "merchantAdmin", "customer", "customer_user"]}>
                        <MainComp comp={<ProductInfo />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/sales-alert"
                element={
                    <ProtectRoute role={["systemAdmin", "merchantAdmin", "customer", "customer_user"]}>
                        <MainComp comp={<SalesAlert />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/customers"
                element={
                    <ProtectRoute role={["admin"]}>
                        <MainComp comp={<Customers />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/projects"
                element={
                    <ProtectRoute role={["admin", "sm_user", "customer", "customer_user"]}>
                        <MainComp comp={<Projects />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/projects/:id"
                element={
                    <ProtectRoute role={["admin", "sm_user", "customer", "customer_user"]}>
                        <MainComp comp={<Products />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/manage-users"
                element={
                    <ProtectRoute role={["admin", "customer"]}>
                        <MainComp comp={<Users />} />
                    </ProtectRoute>
                }
            />
            <Route
                path="/archive-project"
                element={
                    <ProtectRoute role={["admin"]}>
                        <MainComp comp={<ArchiveProject />} />
                    </ProtectRoute>
                }
            />
        </Routes>
    );
}

export default inject("routes")(observer(App));
