import { Button, Col, Form, Row, Typography } from "antd";
import { observer, inject } from "mobx-react";
import React from "react";
import { Logo } from "../../common/logo";
import CustomInput from "../../common/index";
import { useNavigate, useParams } from "react-router-dom";

function SetNewPassword({ auth }) {
    const navigate = useNavigate();
    const { token } = useParams();

    const performForgotPassword = async (values) => {
        delete values.cpassword;
        await auth.setPassword(values, token);
    };
    return (
        <Row className="overflow-hidden p-0 m-0" gutter={24}>
            <Col className="login-left p-0 m-0" xs={24} sm={24} md={12} span={12}>
                <Logo />
            </Col>
            <Col className="login-right p-0 m-0" xs={24} sm={24} md={12} span={12}>
                <Col xs={22} sm={22} md={16} span={16}>
                    <div className="login-helper">
                        <div className="login-helper-title">Set New Password</div>
                        <div className="login-helper-body">
                            <Form layout="vertical" onFinish={performForgotPassword}>
                                <Form.Item
                                    name="password"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter New Password",
                                        },
                                        {
                                            min: 8,
                                            message: "Password needs to be at least 8 characters long.",
                                        },
                                    ]}
                                >
                                    <CustomInput.Password className="w-100 p-1 fs-13" placeholder="********" />
                                </Form.Item>
                                <Form.Item
                                    name="cpassword"
                                    label="Confirm Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Confirm Password",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("New Password & Confirm Password are not same");
                                            },
                                        }),
                                    ]}
                                >
                                    <CustomInput.Password className="w-100 p-1 fs-13" placeholder="********" />
                                </Form.Item>
                                <p className="mt-0 text-right text-black fs-11">
                                    Login?{" "}
                                    <Typography.Link className="fs-11" onClick={() => navigate("/")}>
                                        Here
                                    </Typography.Link>
                                </p>
                                <Button loading={auth.isFetchingData} block htmlType="submit" size="large" className="mt-2 bg-primary text-white btn-custom-height-1 fs-13">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Col>
        </Row>
    );
}

export default inject("auth")(observer(SetNewPassword));
