import React, { useState } from "react";
import { Layout } from "antd";
import SideBar from "./sidebar";
import Header from "./header";
import Footer from "./footer";

const { Content } = Layout;

const App = (props) => {
    const [colsp, setColsp] = useState(false);

    return (
        <Layout>
            <SideBar collapsed={colsp} />

            <Layout
                style={{
                    background: "#F5F7FB",
                }}
            >
                <Header collapsed={colsp} setCollapsed={setColsp}></Header>
                <Content
                    style={{
                        margin: "98px 30px 0",
                        minHeight: "100vh",
                        background: "#F5F7FB",
                    }}
                    className={colsp ? "w_80" : "w_250"}
                >
                    {props.comp}
                </Content>
                <Footer />
            </Layout>
        </Layout>
    );
};

export default App;
