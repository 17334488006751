// import axios from "axios";
import axios from "./Provider/AuthProvider";

class ManageCustomersService {
    getAllCustomers = async ({ page, pageSize, search, sort }) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/customers?role=customer`;
        if (sort) {
            url += `&sortBy=${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`;
        } else {
            url += `&sortBy=createdAt:desc`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (pageSize) {
            url += `&limit=${pageSize}`;
        }
        if (search && search !== "undefined") {
            url += `&search=${search}`;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    getAllCustomersV2 = async ({ page, pageSize, search, sort }) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v2/customers/get_all_customers?role=customer`;
        if (sort) {
            url += `&sortBy=${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`;
        } else {
            url += `&sortBy=createdAt:desc`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (pageSize) {
            url += `&limit=${pageSize}`;
        }
        if (search && search !== "undefined") {
            url += `&search=${search}`;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    getAllCustomerUsers = async () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/customers/customer-users`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    getAllCustomerUsersV2 = async () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v2/customers/customer-users`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    addCustomerService = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v1/auth/customer`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    addCustomerServiceV2 = async (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/v2/customers`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    editCustomerService = async (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v1/customers/${id}`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    editCustomerServiceV2 = async (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API_ENDPOINT}/v2/customers/${id}`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    deleteCustomerService = async (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${process.env.REACT_APP_API_ENDPOINT}/v1/customers/${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    deleteCustomerServiceV2 = async (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${process.env.REACT_APP_API_ENDPOINT}/v2/customers/${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default new ManageCustomersService();
